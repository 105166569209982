import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class CardTableEmptyState extends PureComponent {
  static className = 'c-CardTableEmptyState';
  static propTypes = {
    icon: PropTypes.node,
    message: PropTypes.string.isRequired,
    utilityClass: PropTypes.string,
  };
  static defaultProps = {
    icon: null,
    utilityClass: '',
  };

  render() {
    const { className } = CardTableEmptyState;

    return (
      <div className={`${className} ${this.props.utilityClass}`}>
        {this.props.icon}
        <span className={`${className}__message`}>{this.props.message}</span>
      </div>
    );
  }
}

export default CardTableEmptyState;
