import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PathHelper from 'utils/PathHelper';
import PopupMenu from 'components/PopupMenu';

import MessageIcon from 'components/Icons/MessageIcon';
import DownloadIcon from 'components/Icons/DownloadIcon';
import UserIcon from 'components/Icons/UserIcon';

import i18n from 'config/i18n';

import './index.scss';

class QstreamPopupMenu extends PureComponent {
  static hasDashboardLink = {
    permission: 'access_analytics',
  };

  static propTypes = {
    courseId: PropTypes.number.isRequired,
    checkPermission: PropTypes.func.isRequired,
  };

  prepConfigLinks = () => {
    const { hasDashboardLink } = QstreamPopupMenu;
    const { courseId, checkPermission } = this.props;

    const config = [
      {
        icon: <MessageIcon />,
        label: i18n.t('qstreamActions.viewComments'),
        link: PathHelper.withApiPath(`/courses/${courseId}/comments_report`),
      },
      {
        icon: <DownloadIcon />,
        label: i18n.t('qstreamActions.downloadQuestionAttempts'),
        link: PathHelper.withApiPath(`/course_question_attempts_reports/${courseId}.csv`),
      },
      {
        icon: <DownloadIcon />,
        label: i18n.t('qstreamActions.downloadVideoSubmissionsReport'),
        link: PathHelper.withApiPath(`/reports/video_challenges/video_submissions.csv?course_id=${courseId}`),
      },
    ];

    if (checkPermission(hasDashboardLink)) {
      config.unshift(
        {
          icon: <UserIcon />,
          label: i18n.t('qstreamActions.goToManagerDashboard'),
          link: PathHelper.withApiPath(`/analytics/dashboard?course_object_id=${courseId}&course_object_type=course`),
        },
      );
    }

    return config;
  }

  render() {
    return (
      <PopupMenu config={this.prepConfigLinks()} />
    );
  }
}

export default QstreamPopupMenu;
