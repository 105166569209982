import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from '@qstream/quik';

import './index.scss';

class DataBarLabel extends PureComponent {
  static className = 'c-DataBarLabel';
  static propTypes = {
    number: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    withPercentage: PropTypes.bool,
  }
  static defaultProps = {
    withPercentage: false,
  }

  renderPercentageSign = (withPercentage) => {
    if (!withPercentage) return null;
    const { className } = DataBarLabel;
    return <div className={`${className}__symbol`}>%</div>;
  }

  render() {
    const { className } = DataBarLabel;

    const {
      number,
      text,
      withPercentage,
    } = this.props;

    return (
      <div className={className}>
        <div className={`${className}__number`}>
          <Title>
            {Math.round(number)}{this.renderPercentageSign(withPercentage)}
          </Title>
        </div>
        <div className={`${className}__text`}>
          <Text muted>
            {text}
          </Text>
        </div>
      </div>
    );
  }
}
export default DataBarLabel;
