import graphQLClient from 'config/graphQLClient';
import enterpriseSettingsQuery from 'services/gql/enterpriseSettingsQuery.gql';

export default class EnterpriseService {
  /*
  * Returns essential Enterprise settings.
  *
  * id
  * subdomain
  * coBranding { primary, secondary, text }
  * logo { imageUrl, link, altText }
  *
  */
  static getSettings() {
    return graphQLClient
      .query({
        query: enterpriseSettingsQuery,
      }).then(result => result.data.enterprise);
  }
}
