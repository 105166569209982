import ApolloClient from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import fetch from 'unfetch';
import authHandler from 'config/authHandler';
import tokenManager from 'config/tokenManager';

const httpLink = new HttpLink({
  uri: () => `${authHandler.getData('iss')}/gql`,
  fetch,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = authHandler.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const errorlink = onError(({ networkError, operation, forward }) => {
  if (networkError) {
    /*
    * We can deal with network errors here but for now we
    * only handle the token expiry case.
    */

    if (networkError.statusCode === 401) {
      /*
      * If we receive a 401 response, something went wrong with the request
      * - perhaps the token has expired. Renew the token an try again once.
      *
      * If the user is still logged into Qstream (has an active session),
      * we can renew the token using the `tokenManager.renewToken` method.
      *
      * `renewToken` returns a promise which resolves when the new token has
      * been successfully saved to sessionStorage and rejects if the token couldn't
      * renewed (if the user has been logged out for example).
      */
      return new Observable((observer) => {
        const authTokenUrl = authHandler.getData('auth_token_url');

        tokenManager.renewToken(authTokenUrl)
          .then((newToken) => {
            /*
            * Now that we have a valid token, update the headers of
            * the failed request to use the new token.
            *
            * Note that persistToken here is only necessary to update
            * the payload. The token itself has been persisted to sessionstorage
            * by `entrypoint.html`.
            */
            authHandler.persistToken(newToken);

            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Keep existing headers
                ...headers,
                // Replace the expired access token with the new one
                authorization: `Bearer ${authHandler.getToken()}`,
              },
            }));
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            // Retry the failed request when we have the new token
            forward(operation).subscribe(subscriber);
          })
          .catch((error) => {
            /*
            * If we couldn't refresh the token, force the user to log back into QS-Core
            */
            observer.error(error);
            window.location.href = `${authHandler.getData('iss')}/login`;
          });
      });
    }
  }

  // ESLint requires that we return something
  return undefined;
});

const link = ApolloLink.from([
  authLink,
  errorlink,
  httpLink,
]);

const cache = new InMemoryCache();
const client = new ApolloClient({
  link,
  cache,
});

export default client;
