import React from 'react';
import { Grid, Title } from '@qstream/quik';

import './index.scss';

function UnauthorizedRoute() {
  const className = 'p-UnauthorizedRoute';

  return (
    <div className={className}>
      <Grid>
        <Grid.Column>
          <Title>Unauthorized Route</Title>
          <Title tagName='h2'>This domain is not publicly available at this time</Title>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default UnauthorizedRoute;
