/*
* Assists with positioning elements given the position of their container
*/
export default class PositioningHelper {
  static isAtLeftOfViewport(element) {
    return (element.getBoundingClientRect().left) < (document.documentElement.clientWidth / 2);
  }

  static isAtTopOfViewport(element) {
    return element.getBoundingClientRect().top < (document.documentElement.clientHeight / 2);
  }

  static mediaQuery(query) {
    /*
    * https://www.sitepoint.com/javascript-media-queries/
    *
    * Takes an argument like:
    * "(min-width: 500px)"
    */
    return window.matchMedia(query);
  }
}
