import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import locales from './locales';

i18next
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    defaultNS: 'insightsDashboard',
    ns: ['insightsDashboard'],
    interpolation: { escapeValue: false }, // not needed for react as it escapes by default
    resources: locales,
    react: {
      bindStore: false,
      bindI18n: 'languageChanged',
    },
  });
export default i18next;
