import mixpanel from 'mixpanel-browser';
import authHandler from 'config/authHandler';
import PathHelper from 'utils/PathHelper';

/*
* Mixpanel configuration options:
* https://github.com/mixpanel/mixpanel-js/blob/8b2e1f7b/src/mixpanel-core.js#L87-L110
*
* Mixpanel API Docs
* https://mixpanel.com/help/reference/javascript-full-api-reference
*/
mixpanel.init(process.env.MIXPANEL_TOKEN, {
  api_host: PathHelper.withApiPath(''),
  xhr_headers: { authorization: `Bearer ${authHandler.getToken()}` },
  /*
  *  Mixpanel will send an event by default on page load. We don't need it and if we are in
  *  the process of retrieving a token, the request will be sent to the wrong endpoint anyway.
  */
  track_pageview: false,
});

mixpanel.register({ Service: process.env.SERVICE_NAME });

/*
* When we update our JWT, we need to use the new token
*/
authHandler.registerCallback(
  token => mixpanel.set_config({
    xhr_headers: { authorization: `Bearer ${token}` },
    /*
    *  If there was no token set on initialisation, we need to properly set the API host.
    */
    api_host: PathHelper.withApiPath(''),
  }),
);

export default mixpanel;
