import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { withCoBranding } from '@qstream/quik';
import BackgroundSplodge from './BackgroundSplodge';

import './index.scss';

class WindowDressing extends PureComponent {
  static propTypes = {
    /*
    * isOverallParticipationAndProficiencyLoaded promise
    */

    promise: PropTypes.instanceOf(Promise).isRequired,
    children: PropTypes.node.isRequired,
    cobranding: PropTypes.shape({
      primary: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };

    /*
    * Set states once participation and
    * proficiency cards finished loading
    */
    this.props.promise
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const className = 'c-BackgroundSplodge';

    return (
      <div className='c-WindowDressing'>
        <CSSTransition
          in={!this.state.isLoading}
          timeout={1000}
          classNames={`${className}--animation`}
          mountOnEnter
        >
          <div className={className} >
            <BackgroundSplodge primaryColor={this.props.cobranding.primary} />
          </div>
        </CSSTransition>
        {this.props.children}
      </div>
    );
  }
}

export default withCoBranding(WindowDressing);
