import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import getDisplayName from 'utils/getDisplayName';

const withLoadingState = (WrappedComponent) => {
  class WithLoadingState extends PureComponent {
    static propTypes = {
      promise: PropTypes.instanceOf(Promise).isRequired,
      loadingState: PropTypes.func.isRequired,
    }

    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    componentDidMount = () => {
      this.props.promise
        .then(() => this.setState({ loading: false }))
        .catch(() => this.setState({ loading: false }));
    }

    render() {
      const {
        promise,
        loadingState: LoadingState,
        ...otherProps
      } = this.props;

      const {
        loading,
      } = this.state;

      if (loading) return <LoadingState />;

      return (
        <WrappedComponent {...otherProps} />
      );
    }
  }
  WithLoadingState.displayName = `withLoadingState(${getDisplayName(WrappedComponent)})`;

  return WithLoadingState;
};
export default withLoadingState;
