import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PathHelper from 'utils/PathHelper';
import i18n from 'config/i18n';
import SearchableSummaryText from './SearchableSummaryText';
import SearchableTablesOfQstreams from './SearchableTablesOfQstreams';

// TABLE CONFIG
import configCardTableColumns from './configCardTableColumns';
import configCourseGroupColumns from './configCourseGroupColumns';

class TablesOfQstreams extends PureComponent {
  static propTypes = {
    activeCoursesPromise: PropTypes.instanceOf(Promise).isRequired,
    activeCourses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    completedCoursesPromise: PropTypes.instanceOf(Promise).isRequired,
    completedCourses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    courseGroupsPromise: PropTypes.instanceOf(Promise).isRequired,
    courseGroups: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  }

  selectableRow = (event, data) => {
    window.location.assign(PathHelper.withApiPath(data.adminReportsLink));
  }

  navigateToCourseGroup = (event, data) => {
    window.location.assign(PathHelper.withApiPath(`/reports/admin_reports/course_group/${data.id}`));
  }

  render() {
    const {
      activeCoursesPromise,
      activeCourses,
      completedCoursesPromise,
      completedCourses,
      courseGroupsPromise,
      courseGroups,
    } = this.props;

    return (
      <SearchableTablesOfQstreams
        tables={[{
          id: 't-QstreamOngoing',
          title: i18n.t('tablesOfQstreams.titles.ongoingQstreams'),
          promise: activeCoursesPromise,
          data: activeCourses,
          renderIfEmpty: true,
          emptyStateMessage: i18n.t('tablesOfQstreams.emptyStateMessage.ongoingQstreams'),
          tableConfig: configCardTableColumns,
          defaultSortBy: 'lastAnswered',
          onRowClick: this.selectableRow,
        }, {
          id: 't-QstreamComplete',
          title: i18n.t('tablesOfQstreams.titles.completedQstreams'),
          promise: completedCoursesPromise,
          data: completedCourses,
          renderIfEmpty: true,
          emptyStateMessage: i18n.t('tablesOfQstreams.emptyStateMessage.completedQstreams'),
          tableConfig: configCardTableColumns,
          defaultSortBy: 'lastAnswered',
          onRowClick: this.selectableRow,
        }, {
          id: 't-QstreamGroups',
          title: i18n.t('tablesOfQstreams.titles.courseGroups'),
          promise: courseGroupsPromise,
          data: courseGroups,
          renderIfEmpty: false,
          emptyStateMessage: i18n.t('tablesOfQstreams.emptyStateMessage.courseGroups'),
          tableConfig: configCourseGroupColumns,
          defaultSortBy: 'lastAnswered',
          onRowClick: this.navigateToCourseGroup,
        }]}

        searchableData={filterFunction => [{
          id: 't-QstreamSearch',
          title: i18n.t('tablesOfQstreams.titles.allQstreams'),
          promise: Promise.all([
            activeCoursesPromise,
            completedCoursesPromise,
          ]),
          data: [...activeCourses, ...completedCourses].filter(filterFunction),
          renderIfEmpty: true,
          emptyStateMessage: i18n.t('tablesOfQstreams.emptyStateMessage.allQstreams'),
          tableConfig: configCardTableColumns,
          defaultSortBy: 'lastAnswered',
          onRowClick: this.selectableRow,
        }, {
          title: i18n.t('tablesOfQstreams.titles.courseGroups'),
          promise: courseGroupsPromise,
          data: courseGroups.filter(filterFunction),
          renderIfEmpty: false,
          emptyStateMessage: i18n.t('tablesOfQstreams.emptyStateMessage.courseGroups'),
          tableConfig: configCourseGroupColumns,
          defaultSortBy: 'lastAnswered',
          onRowClick: this.navigateToCourseGroup,
        }]}

        renderSearchableSummary={(filterFunction, searchValue) => (
          <SearchableSummaryText
            searchValue={searchValue}
            numQstreams={[...activeCourses, ...completedCourses].filter(filterFunction).length}
            numCourseGroups={courseGroups.filter(filterFunction).length}
          />
        )}
      />
    );
  }
}
export default TablesOfQstreams;
