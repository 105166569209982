import jwtDecode from 'jwt-decode';

export default class AuthHandler {
  tokenKey = 'qs::accessToken';
  payloadKey = 'qs::jwtPayload';

  constructor() {
    this.callbacks = [];
  }

  registerCallback(callback) {
    this.callbacks.push(callback);
  }

  removeCallback(callback) {
    this.callbacks.splice(this.callbacks.indexOf(callback), 1);
  }

  getToken() {
    return sessionStorage.getItem(this.tokenKey);
  }

  getPayload() {
    return JSON.parse(sessionStorage.getItem(this.payloadKey));
  }

  getData($key) {
    return (this.getPayload() || {})[$key];
  }

  persistToken(token) {
    sessionStorage.setItem(this.tokenKey, token);

    const tokenChanged = this.getData('token') !== token;

    this.persistPayload(token);

    // Only notify watchers on token change
    if (tokenChanged) {
      this.notifyCallbacks(token);
    }
  }

  notifyCallbacks(token) {
    const payload = this.getPayload();
    this.callbacks.forEach(callback => callback(token, payload));
  }

  /*
  *  This is used when the token has changed outside of the AuthHandler (via entrypoint.html) and
  *  we want to update configuration in response.
  */
  forceCallbacks() {
    this.notifyCallbacks(this.getToken());
  }

  persistPayload(token) {
    const payload = AuthHandler.payloadFromToken(token);
    sessionStorage.setItem(this.payloadKey, JSON.stringify(payload));
    return payload;
  }

  /*
  *  Returns true if we have a valid JWT Token, false otherwise
  */
  isAuthenticated() {
    if (!this.getToken()) { return false; }

    try {
      return !!jwtDecode(this.getToken());
    } catch (e) {
      return false;
    }
  }

  static payloadFromToken(token) {
    if (!token) { return null; }

    try {
      const payload = jwtDecode(token);

      // Used to ensure that a persisted payload comes from a particular token.
      payload.token = token;
      return payload;
    } catch (e) {
      throw new Error('Couldn\'t decode JWT payload as the provided token is invalid', e);
    }
  }
}
