import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Link } from '@qstream/quik';

import HorizontalList from 'components/HorizontalList';

import './index.scss';


class HorizontalLinkList extends PureComponent {
  static propTypes = {
    utilityClass: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
        action: PropTypes.func,
      }).isRequired,
    ).isRequired,
  };

  static defaultProps = {
    utilityClass: '',
  };

  renderItems = items => items.map(({ label, link, action }) => {
    if (typeof action === 'function') {
      return (<Link href='#' onClick={action}>{label}</Link>);
    }

    return (<Link href={link} disabled={!link}>{label}</Link>);
  });

  render() {
    const className = 'c-HorizontalLinkList';
    const { items, utilityClass } = this.props;

    return (
      <Grid>
        <Grid.Column>
          <div className={className}>
            <HorizontalList
              utilityClass={utilityClass}
              items={this.renderItems(items)}
            />
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

export default HorizontalLinkList;
