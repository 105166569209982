import React from 'react';

import { Grid } from '@qstream/quik';
import LoadingCard from 'components/LoadingContainer/LoadingCard';
import LoadingContainer from 'components/LoadingContainer';

const ParticipationProficiencyLoadingState = () => (
  <LoadingContainer >
    <Grid noGutters>
      <Grid.Column col={12} sm={6}>
        <LoadingCard iconType='circle' size='medium' secondaryBackground />
      </Grid.Column>
      <Grid.Column col={12} sm={6}>
        <LoadingCard iconType='circle' size='medium' secondaryBackground />
      </Grid.Column>
    </Grid>
  </LoadingContainer>
);
export default ParticipationProficiencyLoadingState;
