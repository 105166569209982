import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class TableText extends PureComponent {
  static className = 'c-TableText';
  static propTypes = {
    /** Sets fakes text from the default of three to two lines if true */
    twoLine: PropTypes.bool,
  };
  static defaultProps = {
    twoLine: false,
  };

  renderNumberOfRows = (number) => {
    const rows = [];
    for (let i = 1; i <= number; i += 1) {
      rows.push((
        <tr key={i}>
          <td /><td /><td /><td /><td /><td /><td /><td />
        </tr>
      ));
    }
    return rows;
  };

  render() {
    const className = 'c-TableText';
    const {
      twoLine,
    } = this.props;

    const activeClassNames = [
      className,
      twoLine ? ` ${className}--twoLine` : '',
    ].filter(Boolean).join(' ');

    return (
      <div className={activeClassNames}>
        <table border='0'>
          <tbody>
            {this.renderNumberOfRows(9)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TableText;
