import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import getDisplayName from 'utils/getDisplayName';

const withTableSort = (WrappedComponent) => {
  class WithTableSort extends PureComponent {
    static propTypes = {
      defaultSortBy: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.object).isRequired,
    }

    constructor(props) {
      super(props);

      this.state = {
        sortBy: this.props.defaultSortBy,
        sortDesc: true,
      };
    }

    onSortClick = (event, dataKey) => {
      const { sortBy } = this.state;

      if (dataKey === sortBy) {
        this.setSortOrder();
      } else {
        this.setSortColumn(dataKey);
      }
      this.sortData();
    }

    setSortOrder = () => this.setState({ sortDesc: !this.state.sortDesc })

    setSortColumn = dataKey => this.setState({
      sortBy: dataKey,
      sortDesc: true,
    })

    resolveNull = value => ((value == null) ? '' : value);

    sortCompare = (a, b) => {
      const { sortBy } = this.state;
      const vA = this.resolveNull(a[sortBy]);
      const vB = this.resolveNull(b[sortBy]);

      if (vA < vB) return -1;
      if (vA > vB) return 1;
      return 0;
    }

    sortData = () => {
      const { sortDesc } = this.state;
      const { data } = this.props;

      // Props are read-only, the .slice side-steps
      // the problem, in this case
      const sorted = data.slice(0).sort(this.sortCompare);
      if (sortDesc) sorted.reverse();
      return sorted;
    }

    render() {
      const { data, ...otherProps } = this.props;

      return (
        <WrappedComponent
          data={this.sortData()}
          sortBy={this.state.sortBy}
          sortDesc={this.state.sortDesc}
          onSortClick={this.onSortClick}

          {...otherProps}
        />
      );
    }
  }

  WithTableSort.displayName = `withTableSort(${getDisplayName(WrappedComponent)})`;
  return WithTableSort;
};

export default withTableSort;
