import React from 'react';

const MicroMegaPhone = () => (
  <svg width="32px" height="18px" viewBox="8 8 32 18" version="1.1">
    <defs>
      <filter x="-17.6%" y="-63.9%" width="135.1%" height="227.8%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0.71221301   0 0 0 0 0.657498729   0 0 0 0 0.657498729  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g id="Appcues-on-Insights" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Appcues-Notice" transform="translate(-1457.000000, -1290.000000)">
        <g id="Group" filter="url(#filter-1)" transform="translate(1443.000000, 1288.000000)" fill="#FFFFFF">
          <g id="Group-6" transform="translate(23.000000, 9.000000)">
            <g id="callout">
              <path d="M14.17925,0.0759 C13.9470961,-0.0422145666 13.6682137,-0.0195303533 13.4582,0.13455 L8.2832,3.92955 L1.19,3.92955 C0.969319066,3.92896546 0.761671814,4.03397289 0.631346221,4.21206179 C0.501020628,4.39015069 0.463741305,4.6198335 0.53105,4.83 L2.49065,10.9779 C2.5823546,11.2642482 2.84892677,11.4582457 3.1496,11.45745 L3.1979,11.45745 L1.535,14.6694 C1.0656987,15.5652283 1.40787096,16.6718936 2.3009,17.1465 C2.7327333,17.3718335 3.23642669,17.416272 3.70104252,17.270028 C4.16565835,17.123784 4.55308774,16.7988528 4.778,16.3668 L7.3379,11.45745 L8.2832,11.45745 L13.4582,15.25245 C13.6682295,15.4059278 13.9467247,15.4282406 14.1785111,15.3101607 C14.4102976,15.1920808 14.5559761,14.9536795 14.5553,14.69355 L14.5553,0.69 C14.5551727,0.430936549 14.4099438,0.193773853 14.17925,0.0759 Z M3.55325,15.72855 C3.43702167,15.9524324 3.16130743,16.0397033 2.937425,15.923475 C2.71354257,15.8072467 2.62627167,15.5315324 2.7425,15.30765 L4.71245,11.51265 L5.74745,11.51265 L3.55325,15.72855 Z M13.17875,13.31355 L8.91455,10.20855 C8.79616037,10.1228354 8.65361025,10.0769295 8.50745,10.07745 L3.6533,10.07745 L2.13185,5.29575 L8.50745,5.29575 C8.65246921,5.29929413 8.79491673,5.25704275 8.91455,5.175 L13.17875,2.07 L13.17875,13.31355 Z" id="Shape" fillRule="nonzero" />
              <path d="M18.18815,12.765 C18.0171433,12.7645536 17.8523948,12.7006213 17.72585,12.5856 L16.2941,11.29185 C16.0082926,11.0365288 15.9835788,10.5978574 16.2389,10.31205 C16.4942212,10.0262426 16.9328926,10.0015288 17.2187,10.25685 L18.65045,11.5506 C18.872052,11.7399838 18.9507334,12.0481969 18.8470243,12.3206269 C18.7433151,12.5930568 18.4795923,12.7709229 18.18815,12.765 Z" id="Path" />
              <path d="M19.6199,8.19375 L16.75985,8.19375 C16.3787735,8.19375 16.06985,7.88482648 16.06985,7.50375 C16.06985,7.12267352 16.3787735,6.81375 16.75985,6.81375 L19.6199,6.81375 C20.0009765,6.81375 20.3099,7.12267352 20.3099,7.50375 C20.3099,7.88482648 20.0009765,8.19375 19.6199,8.19375 Z" id="Path" />
              <path d="M16.75985,4.90935 C16.4816726,4.90818205 16.231403,4.74008409 16.1251071,4.48301365 C16.0188111,4.22594321 16.0772807,3.93018477 16.2734,3.7329 L17.5775,2.415 C17.7519081,2.24059187 18.0061135,2.17247774 18.2443595,2.23631555 C18.4826054,2.30015335 18.6686966,2.48624461 18.7325345,2.72449055 C18.7963723,2.96273648 18.7282581,3.21694187 18.55385,3.39135 L17.2463,4.6989 C17.1186334,4.83101359 16.943551,4.90675848 16.75985,4.90935 Z" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default MicroMegaPhone;
