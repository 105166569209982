import React from 'react';
import formattedDateOrEmptyString from 'utils/formattedDateOrEmptyString';

import CompletionStatus from 'components/Tables/CompletionStatus';
import withPolicy from 'components/withPolicy';
import QstreamPopupMenu from 'pages/Dashboard/QstreamPopupMenu';
import { Trans } from 'react-i18next';

const QstreamPopupMenuWithPolicy = withPolicy(QstreamPopupMenu);

const cardTableColumns = [
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.title' />,
    dataKey: 'title',
    utilityClass: 'u-Flx--2',
    displayTooltip: true,
  },
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.published' />,
    dataKey: 'publishedAt',
    customNode: formattedDateOrEmptyString,
    utilityClass: 'u-Brk--hide u-Brk--visible-above-md',
  },
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.lastAnswered' />,
    dataKey: 'lastAnswered',
    customNode: formattedDateOrEmptyString,
  },
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.participants' />,
    dataKey: 'enrolledUsersCount',
    utilityClass: 'u-Brk--hide u-Brk--visible-above-sm',
  },
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.nonStarters' />,
    dataKey: 'nonStarters',
    utilityClass: 'u-Brk--hide u-Brk--visible-above-sm',
  },
  {
    dataKey: 'completionStatus',
    customNode: data => <CompletionStatus status={data} />,
    utilityClass: 'u-Brk--hide u-Brk--visible-above-md',
  },
  {
    dataKey: 'id',
    customNode: data => (
      <QstreamPopupMenuWithPolicy courseId={data} />
    ),
    utilityClass: 'c-QstreamPopupMenu--set-width',
    allowOverflow: true,
  },
];

export default cardTableColumns;
