import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CardWrapper, Title, Text, Link, Button } from '@qstream/quik';
import megaphoneIcon from 'assets/modalIcons/megaphone.png';
import SideCard from 'components/SideCard';
import { withTranslation } from 'react-i18next';
import TutorialButton from './TutorialButton';
import './index.scss';

class AppcuesSideCard extends PureComponent {
  static className = 'c-AppcuesSideCard';

  static propTypes = {
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      sideCardIsOpen: false,
    };

    this.appcuesLoadedInThisSession = false;
    this.localStorageNotificationToken = 'qs::appcuesTutorialNotification';
    this.localStoragePermissionToken = 'qs::appcuesPermissionNotification';

    if (localStorage.getItem(this.localStorageNotificationToken) === null) {
      setTimeout(() => {
        this.toggleSideCard();
        localStorage.setItem(this.localStorageNotificationToken, true);
      }, 5000);
    }
  }

  loadAndTriggerAppcues = () => {
    this.appcuesLoadedInThisSession = true;
    localStorage.setItem(this.localStoragePermissionToken, true);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//fast.appcues.com/41220.js';
    script.onload = () => window.Appcues.anonymous();
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  closeCardAndRunAppcues = () => {
    this.toggleSideCard();
    this.loadAndTriggerAppcues();
  }

  toggleSideCard = () => this.setState({ sideCardIsOpen: !this.state.sideCardIsOpen });

  runTutorial = () => {
    if (localStorage.getItem(this.localStoragePermissionToken) === null) {
      return this.toggleSideCard();
    } else if (!this.appcuesLoadedInThisSession) {
      return this.loadAndTriggerAppcues();
    }
    return window.Appcues.anonymous();
  }

  render() {
    const { className } = AppcuesSideCard;

    const {
      sideCardIsOpen,
    } = this.state;
    const { t } = this.props;

    return (
      <React.Fragment>
        <SideCard isOpen={sideCardIsOpen}>
          <div className={className}>
            <CardWrapper>
              <img className={`${className}__icon`} alt='' src={megaphoneIcon} />

              <div className={`${className}__title`}>
                <Title tagName='h2'>
                  {t('tutorial.title')}
                </Title>
              </div>
              <div className={`${className}__body`}>
                <Text>
                  {t('tutorial.consentText')}
                </Text>
              </div>
              <div className={`${className}__buttons`}>
                <Button onClick={this.toggleSideCard}>{t('tutorial.notRightNow')}</Button>
                <Button onClick={this.closeCardAndRunAppcues}>{t('tutorial.letsGo')}</Button>
              </div>
              <div className={`${className}__policy`}>
                <Link href='https://qstream.com/privacy/' target='_blank'>{t('tutorial.viewPrivacyPolicy')}</Link>
              </div>
            </CardWrapper>
          </div>
        </SideCard>
        <TutorialButton onClick={this.runTutorial} />
      </React.Fragment>
    );
  }
}
export default withTranslation()(AppcuesSideCard);
