import React from 'react';

import { Grid } from '@qstream/quik';
import LoadingCard from 'components/LoadingContainer/LoadingCard';
import LoadingContainer from 'components/LoadingContainer';

const SummaryOfParticipationLoadingState = () => (
  <LoadingContainer >
    <Grid noGutters>
      <Grid.Column >
        <LoadingCard size='small' secondaryBackground textTwoLine />
      </Grid.Column>
    </Grid>
  </LoadingContainer>
);
export default SummaryOfParticipationLoadingState;
