/*
*  Takes a URL and returns everything before the first slash after the host.
*/
export const schemeAndHostFromUrl = url => url.replace(/(https?:\/\/.*?)(\/.*?)$/, '$1');

/*
*  Extracts the subdomain from the given URL
*/
export const subdomainFromUrl = url => url.match(/https?:\/\/(.*?)\./)[1];

/*
*  Replaces the subdomain in the given URL
*/
export const replaceSubdomain = (url, replacement) => url.replace(/(https?:\/\/)(.*?)\./, `$1${replacement}.`);

export default {
  schemeAndHostFromUrl,
  subdomainFromUrl,
  replaceSubdomain,
};
