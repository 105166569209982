import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class RadioButton extends PureComponent {
  static className = 'c-RadioButton';
  static propTypes = {
    selected: PropTypes.bool.isRequired,
  }

  render() {
    const { className } = RadioButton;
    const { selected, ...rest } = this.props;

    const activeClassNames = [
      selected ? `${className}__button--active` : '',
    ].filter(Boolean).join(' ');

    return (
      <div className={className}>
        <div
          className={`${className}__button ${activeClassNames}`}
          {...rest}
        />
      </div>
    );
  }
}

export default RadioButton;
