import en from './translations/locales/en';
import it from './translations/locales/it';
import deDE from './translations/locales/de-DE';
import es from './translations/locales/es';
import fr from './translations/locales/fr';
import idID from './translations/locales/id-ID';
import ja from './translations/locales/ja';
import ko from './translations/locales/ko';
import nl from './translations/locales/nl';
import plPL from './translations/locales/pl-PL';
import ptPT from './translations/locales/pt-PT';
import ptBR from './translations/locales/pt-BR';
import ruRU from './translations/locales/ru-RU';
import th from './translations/locales/th';
import tr from './translations/locales/tr';
import vi from './translations/locales/vi';
import zhCN from './translations/locales/zh-CN';

export default {
  en,
  it,
  'de-DE': deDE,
  es,
  fr,
  'id-ID': idID,
  ja,
  ko,
  nl,
  'pl-PL': plPL,
  'pt-PT': ptPT,
  'pt-BR': ptBR,
  'ru-RU': ruRU,
  th,
  tr,
  vi,
  'zh-CN': zhCN,
};
