import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import sortByNumber from 'utils/sortByNumber';

import './index.scss';

class DataBar extends PureComponent {
  static className = 'c-DataBar';
  static propTypes = {
    percentages: PropTypes.arrayOf(
      PropTypes.number.isRequired,
    ).isRequired,
    theme: PropTypes.oneOf(['', 'positive']),
  }
  static defaultProps = {
    theme: '',
  }

  sortHigherLowerPercentage = () => {
    const { percentages } = this.props;
    const percentageOne = percentages[0];
    const percentageTwo = percentages[1];

    if (percentageTwo === 0) {
      /** If percentageTwo is zero, it sets percentageOne to the darker coloured bar */
      return [0, percentageOne];
    }
    return [percentageOne, percentageTwo].sort(sortByNumber).reverse();
  }

  renderPercentageBarValue = (value, type) => {
    const { className } = DataBar;
    return (
      <div
        className={`${className}__bar-data ${className}__bar-${type}`}
        style={{ width: `${value}%` }}
      />
    );
  }

  render() {
    const { className } = DataBar;
    const sorted = this.sortHigherLowerPercentage();

    const {
      theme,
    } = this.props;

    const activeClassNames = [
      className,
      theme ? `${className}--color-${theme}` : '',
    ].filter(Boolean).join(' ');

    return (
      <div className={activeClassNames}>
        <div className={`${className}__bar-bg`}>
          {this.renderPercentageBarValue(sorted[0], 'higher')}
          {this.renderPercentageBarValue(sorted[1], 'lower')}
        </div>
      </div>
    );
  }
}
export default DataBar;
