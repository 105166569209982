import React, { PureComponent } from 'react';
import { Button } from '@qstream/quik';
import PropTypes from 'prop-types';
import MicroMegaPhone from 'components/Icons/MicroMegaPhone';
import { withTranslation } from 'react-i18next';
import './index.scss';

class TutorialButton extends PureComponent {
  static className = 'c-TutorialButton';

  static propTypes = {
    onClick: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { className } = TutorialButton;

    const { onClick, t } = this.props;

    return (
      <div className={className}>
        <Button onClick={onClick}>
          <MicroMegaPhone />{t('tutorial.startButton')}
        </Button>
      </div>
    );
  }
}
export default withTranslation()(TutorialButton);
