import React from 'react';
import formattedDateOrEmptyString from 'utils/formattedDateOrEmptyString';
import { Trans } from 'react-i18next';
import CourseGroupPopupMenu from 'pages/Dashboard/CourseGroupPopupMenu';

const courseGroupColumns = [
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.title' />,
    dataKey: 'title',
    utilityClass: 'u-Flx--2',
    displayTooltip: true,
  },
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.numberOfCourses' />,
    dataKey: 'courseCount',
    utilityClass: 'u-Brk--hide u-Brk--visible-above-md',
  },
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.lastAnswered' />,
    dataKey: 'lastAnswered',
    customNode: formattedDateOrEmptyString,
  },
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.participants' />,
    dataKey: 'participants',
    utilityClass: 'u-Brk--hide u-Brk--visible-above-sm',
  },
  {
    columnTitle: <Trans i18nKey='tablesOfQstreams.columnHeaders.nonStarters' />,
    dataKey: 'nonStarters',
    utilityClass: 'u-Brk--hide u-Brk--visible-above-sm',
  },
  {
    dataKey: 'id',
    customNode: data => <CourseGroupPopupMenu courseGroupId={data} />,
    utilityClass: 'c-CourseGroupPopupMenu--set-width',
    allowOverflow: true,
  },
];

export default courseGroupColumns;
