import React, { PureComponent } from 'react';
import { Grid, Title, Text, CardWrapper } from '@qstream/quik';

import Metric from 'components/Metric';
import teamIcon from 'assets/metricIcons/team.svg';
import ongoingIcon from 'assets/metricIcons/speedometer.svg';
import completedIcon from 'assets/metricIcons/checklist.svg';

import CardTable from 'components/Tables/CardTable';
import Table from 'components/Tables/Table';
import CompletionStatus from 'components/Tables/CompletionStatus';

import CourseService from 'services/CourseService';
import configTableColumns from 'pages/Dashboard/configTableColumns';
import configCardTableColumns from 'pages/Dashboard/TablesOfQstreams/configCardTableColumns';

import LoadingCard from 'components/LoadingContainer/LoadingCard';
import LoadingContainer from 'components/LoadingContainer';

import VisualizationCard from 'components/VisualizationCard';
import DataBarContainer from 'components/VisualizationCard/DataBarContainer';
import DataDonut from 'components/VisualizationCard/DataDonut';
import DataArrow from 'components/VisualizationCard/DataArrow';

import Select from 'components/Input/Select';
import SummaryCard from 'components/SummaryCard';
import AppcuesSideCard from '../Dashboard/AppcuesSideCard';

import './index.scss';

const tableSetup = {
  tableTitle: 'List of Qstreams',
};

const selectOptions = [
  'FIRST',
  'Country', 'City', 'Town', 'Country2', 'City2', 'Town2', 'Country3',
  'This is an incredibly long tag name This is an incredibly long tag name',
  'ThisisanincrediblylongtagnameThisisanincrediblylongtagnameThisisanincrediblylongtagname',
  'LAST',
];

class KitchenSink extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      selectedOption: null,
      barDataOne: 0,
      barDataTwo: 0,
      percentage: 0,
      percentageNegative: 0,
      enrollments: 0,
      qstreams: 0,
      pastFirstPresentation: 0,
    };
  }

  componentDidMount() {
    CourseService
      .managedCourses()
      .then(courses => this.setState({ tableData: courses }));
    setTimeout(() => this.setState({
      barDataOne: 273,
      barDataTwo: 100,
      percentage: 37,
      percentageNegative: -37,
      enrollments: 50,
      qstreams: 2,
      pastFirstPresentation: 40,
    }), 2000);
  }

  render() {
    const className = 'p-KitchenSink';

    return (
      <div className={className}>
        <Grid>
          <Grid.Column>
            <Title>Kitchen Sink</Title>
            <Text>Welcome to Qstream React Boilerplate</Text>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column col={0} lg={4}>
            <Metric icon={teamIcon} label='Participants' data={363} />
          </Grid.Column>
          <Grid.Column col={12} sm={6} lg={4} md={6}>
            <Metric icon={ongoingIcon} label='Ongoing Qstreams' data={15} />
          </Grid.Column>
          <Grid.Column col={12} sm={6} lg={4} md={6}>
            <Metric icon={completedIcon} label='Completed Qstreams' data={4} />
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column col={12} sm={6}>
            <VisualizationCard
              label='OVERALL PARTICIPATION'
              subLabel='Based across all Qstreams'
              firstNode={<DataDonut percentage={this.state.percentage} />}
              secondNode={
                <DataBarContainer
                  data={[
                    {
                      number: this.state.barDataOne,
                      text: 'Started',
                      withPercentage: false,
                      barPercentage: parseInt(
                        (this.state.barDataOne
                          /
                        ((this.state.barDataOne + this.state.barDataTwo) / 100)), 0,
                      ),
                    },
                    {
                      number: this.state.barDataTwo,
                      text: 'Not Started',
                      withPercentage: false,
                      barPercentage: this.state.barDataTwo,
                    },
                  ]}
                />
              }
            />
          </Grid.Column>
          <Grid.Column col={12} sm={6}>
            <VisualizationCard
              label='PROFICIENCY INCREASE'
              subLabel='Based across all Qstreams'
              firstNode={
                <DataArrow percentage={this.state.percentageNegative} />
              }
              secondNode={
                <DataBarContainer
                  barTheme='positive'
                  data={[{
                    number: this.state.barDataOne / 10,
                    text: 'Avg Initial',
                    withPercentage: true,
                    barPercentage: parseInt(
                      ((this.state.barDataOne - 100)
                        /
                      ((this.state.barDataOne + this.state.barDataTwo) / 100)), 0,
                    ),
                  }, {
                    number: this.state.barDataTwo / 10,
                    text: 'Avg Current',
                    withPercentage: true,
                    barPercentage: this.state.barDataTwo - 20,
                  }]}
                />
              }
            />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <SummaryCard
              sentences={[
                'You\'ve {enrollments} across {qstreams}.',
                '{pastFirstPresentation} progressed past first presentation.',
                'The average proficiency gain is {proficiencyGain}.',
              ]}
              replacements={[{
                  placeholder: '{proficiencyGain}',
                  value: `${this.state.percentageNegative}%`,
                }, {
                  placeholder: '{enrollments}',
                  value: this.state.enrollments,
                  singular: 'enrollment',
                  plural: 'enrollments',
                }, {
                  placeholder: '{qstreams}',
                  value: this.state.qstreams,
                  singular: 'Qstream',
                  plural: 'Qstreams',
                }, {
                  placeholder: '{pastFirstPresentation}',
                  value: this.state.pastFirstPresentation,
                  singular: 'participant',
                  plural: 'participants',
              }]}
            />
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <Text>
              How are your team getting on with their Qstreams
            </Text>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column>
            <CardTable
              setup={tableSetup}
              columns={configCardTableColumns}
              data={this.state.tableData}
              emptyStateMessage='Empty State Message Goes here!'
            />
          </Grid.Column>
          <Grid.Column>
            <CardWrapper>
              <div className="u-Pad--m3">
                <Table
                  setup={tableSetup}
                  columns={configTableColumns}
                  data={this.state.tableData}
                />
              </div>
            </CardWrapper>
          </Grid.Column>
          <Grid.Column>
            <Grid>
              <Grid.Column col={6}>
                <Select
                  value={this.state.selectedOption}
                  values={selectOptions}
                  onChange={newVal => this.setState({ selectedOption: newVal })}
                />
              </Grid.Column>
              <Grid.Column col={6}>
                <div style={{ textAlign: 'right' }}>
                  <Select
                    value={this.state.selectedOption}
                    values={selectOptions}
                    onChange={newVal => this.setState({ selectedOption: newVal })}
                  />
                </div>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column col={12} sm={4}>
                <CardWrapper>
                  <div className="u-Pad--m2">
                    <CompletionStatus status='NOT_STARTED' />
                  </div>
                </CardWrapper>
              </Grid.Column>
              <Grid.Column col={12} sm={4}>
                <CardWrapper>
                  <div className="u-Pad--m2">
                    <CompletionStatus status='ACTIVE' />
                  </div>
                </CardWrapper>
              </Grid.Column>
              <Grid.Column col={12} sm={4}>
                <CardWrapper>
                  <div className="u-Pad--m2">
                    <CompletionStatus status='COMPLETE' />
                  </div>
                </CardWrapper>
              </Grid.Column>
              <Grid.Column>
                <Title tagName='h4' >Loading States</Title>
                <Text muted>
                  The loading states below have 2 different background
                   colors (primary and secondaryBackground)
                   based on the colors that exist in storybook. <br />
                   There are three different sizes too small, medium, & large.
                </Text>
              </Grid.Column>
              <Grid.Column>
                <LoadingContainer>
                  <LoadingCard secondaryBackground emptyCardTableRow />
                  <LoadingCard secondaryBackground emptyCardTableRow />
                </LoadingContainer>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
        <LoadingContainer>
          <Grid noGutters>
            <Grid.Column col={0} lg={4}>
              <LoadingCard secondaryBackground text={false} />
            </Grid.Column>
            <Grid.Column col={12} sm={6} lg={4} md={6}>
              <LoadingCard secondaryBackground iconType='circle' />
            </Grid.Column>
            <Grid.Column col={12} sm={6} lg={4} md={6}>
              <LoadingCard secondaryBackground textTwoLine />
            </Grid.Column>
            <Grid.Column>
              <LoadingCard iconType='square' size='medium' />
            </Grid.Column>
            <Grid.Column>
              <LoadingCard size='large' />
            </Grid.Column>
          </Grid>
        </LoadingContainer>
        <AppcuesSideCard />
      </div>
    );
  }
}

export default KitchenSink;
