import React, { PureComponent } from 'react';
import { Title } from '@qstream/quik';
import PropTypes from 'prop-types';

import DataArrowIcon from 'components/Icons/DataArrowIcon';

import './index.scss';

class DataArrow extends PureComponent {
  static className = 'c-DataArrow';
  static propTypes = {
    percentage: PropTypes.number,
  }

  static defaultProps = {
    percentage: 0,
  }

  addNegativeClassNameModifier = () => {
    const { className } = DataArrow;
    const {
      percentage,
    } = this.props;

    if (Math.round(percentage) === 0) {
      return `${className}--none`;
    } else if (percentage < 0) {
      return `${className}--negative`;
    }
    return '';
  }

  cleanMinusSign = percentage => Math.abs(Math.round(percentage));

  render() {
    const { className } = DataArrow;

    const {
      percentage,
    } = this.props;

    const activeClassNames = [
      className,
      this.addNegativeClassNameModifier(),
    ].filter(Boolean).join(' ');

    return (
      <div className={activeClassNames}>
        <div className={`${className}__arrow`}>
          <DataArrowIcon />
        </div>
        <div className={`${className}__number`}>
          <Title>{this.cleanMinusSign(percentage)}
            <div className={`${className}__symbol`}>%</div>
          </Title>
        </div>
      </div>
    );
  }
}
export default DataArrow;
