import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import VisualizationCard from 'components/VisualizationCard';
import DataBarContainer from 'components/VisualizationCard/DataBarContainer';
import DataDonut from 'components/VisualizationCard/DataDonut';
import i18n from 'config/i18n';

class OverallParticipation extends PureComponent {
  static className = 'c-OverallParticipation';
  static propTypes = {
    starters: PropTypes.number.isRequired,
    nonStarters: PropTypes.number.isRequired,
    percentEnrollmentsStarted: PropTypes.number.isRequired,
  }

  renderDataDonut = () => (
    <DataDonut percentage={this.props.percentEnrollmentsStarted} />
  );

  renderDataBarContainer = () => {
    const {
      starters,
      nonStarters,
      percentEnrollmentsStarted,
    } = this.props;

    return (
      <DataBarContainer
        data={[{
          number: starters,
          text: i18n.t('participationCard.started'),
          withPercentage: false,
          barPercentage: percentEnrollmentsStarted,
        }, {
          number: nonStarters,
          text: i18n.t('participationCard.notStarted'),
          withPercentage: false,
          barPercentage: 0,
        }]}
      />
    );
  };

  render() {
    return (
      <VisualizationCard
        id='t-OverallParticipation'
        label={i18n.t('participationCard.title')}
        subLabel={i18n.t('participationCard.subLabel')}
        firstNode={this.renderDataDonut()}
        secondNode={this.renderDataBarContainer()}
      />
    );
  }
}
export default OverallParticipation;
