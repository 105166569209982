import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TableText from '../TableText';
import './index.scss';

class LoadingCard extends PureComponent {
  static className = 'c-LoadingCard';
  static propTypes = {
    /** None if left blank and sets text to full width. */
    iconType: PropTypes.oneOf(['', 'circle', 'square']),
    /** Sets the sizing of the inner content. (default) small: 64px, medium: 128px, large: 192px */
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    /** Passes a prop to `<TableText />` which changes the fake text lines from three to two. */
    textTwoLine: PropTypes.bool,
    /** If text is set to false an empty card will appear with a transparent background. */
    text: PropTypes.bool,
    /** Overrides the size props and adds `display: none` to the inner content.
    Used to create a loading state for the card table
    */
    emptyCardTableRow: PropTypes.bool,
    /** Changes the mask color to secondary background color derived from Quik storybook. */
    secondaryBackground: PropTypes.bool,
  };

  static defaultProps = {
    iconType: '',
    size: 'small',
    textTwoLine: false,
    text: true,
    emptyCardTableRow: false,
    secondaryBackground: false,
  };

  setCardTableRow = () => {
    const { className } = LoadingCard;
    const {
      emptyCardTableRow,
    } = this.props;

    if (!emptyCardTableRow) return '';

    return ` ${className}--emptyCardTableRow`;
  }
  renderIcon = () => {
    const {
      iconType,
    } = this.props;
    const { className } = LoadingCard;

    if (!iconType) return null;

    return (
      <React.Fragment>
        <div className={`${className}__square`}>
          { iconType === 'circle' && <div className={`${className}__circle`} /> }
        </div>
        <div className={`${className}__row-spacer`} />
      </React.Fragment>
    );
  };

  renderText = () => {
    const { className } = LoadingCard;
    const {
      text,
      textTwoLine,
      iconType,
    } = this.props;

    const activeClassNames = [
      `${className}__text-container`,
      iconType ? '' : `${className}--full-width`,
    ].filter(Boolean).join(' ');

    if (!text) return null;

    return (
      <div className={activeClassNames}>
        <TableText twoLine={textTwoLine} />
      </div>
    );
  };

  render() {
    const { className } = LoadingCard;
    const {
      size,
      secondaryBackground,
    } = this.props;

    const activeClassNames = [
      className,
      `${className}--${size}`,
      secondaryBackground ? `${className}--bg-secondary` : '',
      this.setCardTableRow(),
    ].filter(Boolean).join(' ');

    return (
      <div className={activeClassNames}>
        <div className={`${className}__outer`}>
          <div className={`${className}__row`}>
            {this.renderIcon()}
            {this.renderText()}
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingCard;
