import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Grid, Title } from '@qstream/quik';
import Metric from 'components/Metric';
import WindowDressing from 'components/WindowDressing';
import Tagline from 'components/Tagline';

// ICONS
import teamIcon from 'assets/metricIcons/team.svg';
import ongoingIcon from 'assets/metricIcons/speedometer.svg';
import completedIcon from 'assets/metricIcons/checklist.svg';

import i18n from 'config/i18n';

class Overview extends PureComponent {
  static propTypes = {
    /*
    * isOverallParticipationAndProficiencyLoaded promise
    */
    id: PropTypes.string,
    promise: PropTypes.instanceOf(Promise).isRequired,
    teamMemberCount: PropTypes.number.isRequired,
    activeCoursesCount: PropTypes.number.isRequired,
    completedCoursesCount: PropTypes.number.isRequired,
    cobranding: PropTypes.object,
  }

  static defaultProps = {
    id: '',
    cobranding: {},
  }

  render() {
    const {
      id,
      promise,
      teamMemberCount,
      activeCoursesCount,
      completedCoursesCount,
      cobranding,
    } = this.props;

    return (
      <div id={id} className='c-Overview'>
        <WindowDressing promise={promise}>
          <Grid>
            <Grid.Column>
              <Title tagName='h3' light>
                <span style={{ color: cobranding.contrastingTextColor }}>
                  {i18n.t('app.welcome')}
                </span>
              </Title>
              <Tagline textColor={cobranding.contrastingTextColor}>
                {i18n.t('overview.description')}
              </Tagline>
            </Grid.Column>
            <Grid.Column col={0} lg={4}>
              <Metric
                icon={teamIcon}
                label={i18n.t('overview.participants', { count: teamMemberCount })}
                data={teamMemberCount}
              />
            </Grid.Column>
            <Grid.Column col={12} sm={6} lg={4} md={6}>
              <Metric
                icon={ongoingIcon}
                label={i18n.t('overview.ongoingQstreams', { count: activeCoursesCount })}
                data={activeCoursesCount}
              />
            </Grid.Column>
            <Grid.Column col={12} sm={6} lg={4} md={6}>
              <Metric
                icon={completedIcon}
                label={i18n.t('overview.completedQstreams', { count: completedCoursesCount })}
                data={completedCoursesCount}
              />
            </Grid.Column>
          </Grid>
        </WindowDressing>
      </div>
    );
  }
}

export default Overview;
