import React, { PureComponent } from 'react';

import getDisplayName from 'utils/getDisplayName';
import policy from 'config/policy';

const withPolicy = (WrappedComponent) => {
  class WithPolicy extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        policyObj: [],
      };
    }

    getPolicy = (permission) => {
      const policyPromise = policy.getPolicy([permission]);
      return policyPromise.then(policyUpdate => (
        this.setState({
          policyObj: [
            ...this.state.policyObj,
            ...policyUpdate,
          ],
        })
      ));
    }

    checkPermission = (permission) => {
      const { policyObj } = this.state;

      const hasPermissionInState = policyObj.filter(checkPolicy => (
        permission.permission === checkPolicy.permission
      ));

      if (hasPermissionInState.length <= 0) {
        return this.getPolicy(permission);
      }

      const isPolicyAllowed = hasPermissionInState.find(checkPolicy => checkPolicy.allowed);
      if (isPolicyAllowed) {
        return true;
      }
      return false;
    }

    render() {
      const { policyObj } = this.state;
      const { ...otherProps } = this.props;
      return (
        <WrappedComponent
          policyObj={policyObj}
          checkPermission={this.checkPermission}
          {...otherProps}
        />
      );
    }
  }

  WithPolicy.displayName = `withPolicy(${getDisplayName(WrappedComponent)})`;
  return WithPolicy;
};

export default withPolicy;
