import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { UIView } from '@uirouter/react';
import { NavBar, CoBrandingProvider } from '@qstream/quik';
import ErrorBoundary from 'components/ErrorBoundary';
import PathHelper from 'utils/PathHelper';
import i18n from 'config/i18n';
import { I18nextProvider } from 'react-i18next';

import 'assets/favicon.ico';

class App extends PureComponent {
  static propTypes = {
    user: PropTypes.instanceOf(Promise).isRequired,
    enterprise: PropTypes.instanceOf(Promise).isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      user: {
        primaryNav: [
          {
            label: 'Dashboard',
            link: PathHelper.withApiPath('/'),
            type: 'standard',
          },
        ],
        locale: null,
      },
      enterprise: {
        coBranding: { },
        logo: {
          imageUrl: PathHelper.withApiPath('/images/qstream-logo-160.png'),
          link: PathHelper.withApiPath('/'),
          altText: 'Qstream',
        },
      },
    };

    this.props.user.then((user) => {
      this.setState({ user });
      i18n.changeLanguage(user.locale);
    });
    this.props.enterprise.then(enterprise => this.setState({ enterprise }));
  }

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <CoBrandingProvider value={this.state.enterprise.coBranding}>
          <NavBar
            links={this.state.user.primaryNav}
            logo={this.state.enterprise.logo}
            disableResponsiveBehaviour={false}
          />
          <ErrorBoundary>
            <UIView />
          </ErrorBoundary>
        </CoBrandingProvider>
      </I18nextProvider>
    );
  }
}

export default App;
