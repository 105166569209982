import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './index.scss';

class SideCard extends PureComponent {
  static className = 'c-SideCard';
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
  }

  render() {
    const { className } = SideCard;

    const {
      isOpen,
      children,
    } = this.props;

    return (
      <CSSTransition
        in={isOpen}
        timeout={1000}
        classNames={`${className}-`}
        mountOnEnter
        unmountOnExit
      >
        <div className={className}>
          {children}
        </div>
      </CSSTransition>
    );
  }
}
export default SideCard;
