import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PathHelper from 'utils/PathHelper';
import PopupMenu from 'components/PopupMenu';

import MessageIcon from 'components/Icons/MessageIcon';

import i18n from 'config/i18n';

import './index.scss';

class CourseGroupPopupMenu extends PureComponent {
  static propTypes = {
    courseGroupId: PropTypes.number.isRequired,
  };

  prepConfigLinks = () => {
    const { courseGroupId } = this.props;
    const config = [
      {
        icon: <MessageIcon />,
        label: i18n.t('courseGroupActions.viewComments'),
        // the link appears to have a duplicate course group id
        link: PathHelper.withApiPath(`/course_groups/${courseGroupId}/comments_report?course_group_id=${courseGroupId}`),
      },
    ];

    return config;
  }

  render() {
    return (
      <PopupMenu config={this.prepConfigLinks()} />
    );
  }
}

export default CourseGroupPopupMenu;
