import gql from 'graphql-tag';

import authHandler from 'config/authHandler';
import graphQLClient from 'config/graphQLClient';

/*
* PolicyService handles all data requests and stores relating to
* user permission policies. Policy integration with the least
* requests & lowest use overhead. The 'resource' parameter has
* not yet been applied here but can readily be applied as it is
* already available on the User::canDo field.
*
* 'Permission' is an action or role name e.g. site_admin or
* view_catalogue
*
* 'Policy' is the data structure that relates to the permission/action,
* a resource and whether the current User is allowed (boolean).
*/

export default class PolicyService {
  constructor() {
    this.policyCache = [];
    this.policyInFlight = [];
  }

  getPolicy(requestedPolicies) {
    const requiredPolicies = requestedPolicies.filter(policy => (
      !this.policyAvailableIn(this.policyCache, policy.permission) &&
      !this.policyAvailableIn(this.policyInFlight, policy.permission)
    ));

    let permissionsToFetch = null;
    if (requiredPolicies.length > 0) {
      permissionsToFetch = this.requestPolicyData(requiredPolicies);
    }

    const inFlightPromises = this.policyInFlight.map(policy => policy.promise);

    return this.resolvePolicyPromises([...inFlightPromises, permissionsToFetch], requestedPolicies);
  }

  resolvePolicyPromises = (arrayOfPromises, requestedPolicies) =>
    Promise.all(arrayOfPromises)
      .then(() => this.getRequestedPoliciesFromCache(requestedPolicies));

  getRequestedPoliciesFromCache = requestedPolicies => (
    this.policyCache.filter(cachedPolicy => (
      requestedPolicies.find(policy => (policy.permission === cachedPolicy.permission))
    ))
  )

  policyAvailableIn = (policyStore, permission) =>
    policyStore.find(storedPolicy => (permission === storedPolicy.permission));

  addRequestsInFlight = (requestedPolicies, promise) => (
    requestedPolicies.forEach((policy) => {
      this.policyInFlight.push({
        permission: policy.permission,
        promise,
      });
    })
  )

  removeRequestsInFlight = requestedPolicies => (
    this.policyInFlight.filter(inFlightPolicy => (
      requestedPolicies.find(policy => (policy.permission !== inFlightPolicy.permission))
    ))
  )

  addResponsesToCache = (policies, data) => {
    policies.forEach((policy, index) => {
      this.policyCache.push({
        permission: policy.permission,
        allowed: Boolean(data[`policy${index}`].canDo),
      });
    });
  }

  generatePolicyQuery = (requestedPolicies) => {
    const queryStr = requestedPolicies.map((policy, index) =>
      (
        `
        policy${index}: user(id: $id) {
          id
          canDo(action: "${policy.permission}")
        }`
      ));
    return gql(String.raw`query User($id: Int!) { ${queryStr} }`);
  }

  requestPolicyData(requestedPolicies) {
    const user = authHandler.getData('user');
    const policyQuery = this.generatePolicyQuery(requestedPolicies);

    const requestPromise = graphQLClient
      .query({ query: policyQuery, variables: { id: user.id } })
      .then((result) => {
        this.addResponsesToCache(requestedPolicies, result.data);
        this.removeRequestsInFlight(requestedPolicies);
      })
      .catch(() => {
        this.removeRequestsInFlight(requestedPolicies);
      });

    this.addRequestsInFlight(requestedPolicies, requestPromise);
    return requestPromise;
  }
}
