import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class Tagline extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    textColor: PropTypes.string,
  }

  static defaultProps = {
    textColor: '',
  }

  render() {
    const {
      children,
      textColor,
    } = this.props;

    return (
      <p className='c-Tagline' style={{ color: textColor }}>
        {children}
      </p>
    );
  }
}

export default Tagline;
