import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { handleEnterEvent } from '@qstream/quik';

import RadioButton from 'components/Input/RadioButton';

import './index.scss';

class SelectOption extends PureComponent {
  static className = 'c-SelectOption';

  static propTypes = {
    value: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
  };


  constructor(props) {
    super(props);

    this.selectValue = this.selectValue.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  selectValue(evt) {
    evt.preventDefault();

    this.props.onSelect(this.props.value);
  }

  handleKeyPress(evt) {
    handleEnterEvent(evt, () => this.selectValue(evt));
  }

  render() {
    const { className } = SelectOption;
    const {
      onSelect, value, selected, ...rest
    } = this.props;

    return (
      <li
        className={className}
        role="option"
        tabIndex="0"
        onClick={this.selectValue}
        onKeyPress={this.handleKeyPress}
        aria-selected={selected}
        title={value}
        {...rest}
      >
        <RadioButton selected={selected} />
        <div className={`${className}__value`}>
          { value }
        </div>
      </li>
    );
  }
}

export default SelectOption;
