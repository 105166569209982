import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DataBarLabel from './DataBarLabel';
import DataBar from './DataBar';

import './index.scss';

class DataBarContainer extends PureComponent {
  static className = 'c-DataBarContainer';
  static propTypes = {
    /** data Array should be of length === 2 */
    data: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      withPercentageSign: PropTypes.bool,
      /**
       * The second 50% opacity bar can be hidden with a value of 0. 100% equals full width.
       * The bars automatically sort so that the one with higher value is passed to the longer bar
       */
      barPercentage: PropTypes.number.isRequired,
    }).isRequired).isRequired,
    barTheme: PropTypes.oneOf(['', 'positive']),
  }

  static defaultProps = {
    barTheme: '',
  }

  getPercentagesFromObject = data => data.map(({ barPercentage }) => barPercentage);

  renderBarLabel = ({ number, text, withPercentage }) => (
    <DataBarLabel
      number={number}
      text={text}
      withPercentage={withPercentage}
    />
  );

  render() {
    const { className } = DataBarContainer;
    const {
      data,
      barTheme,
    } = this.props;

    return (
      <div className={className}>
        {this.renderBarLabel(data[0])}
        <DataBar percentages={this.getPercentagesFromObject(data)} theme={barTheme} />
        {this.renderBarLabel(data[1])}
      </div>
    );
  }
}
export default DataBarContainer;
