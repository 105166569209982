import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DropdownWrapper, Toggle } from '@qstream/quik';

import PositioningHelper from 'utils/PositioningHelper';

import MoreIcon from 'components/Icons/MoreIcon';

import './index.scss';

const className = 'c-PopupMenu';

class PopupMenu extends PureComponent {
/*
*   config prop takes an array of objects with icon, label and link
*   Example:
*   [{ icon: <UserIcon />, label: 'Go to Manager Dashboard', link: 'www' }]
*/

  static propTypes = {
    config: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
  }

  isAtLeftOfViewport = () => {
    if (!this.menuRef.current) {
      return {};
    }

    return PositioningHelper.isAtLeftOfViewport(this.menuRef.current);
  }

  buildClassName = () => {
    const style = [];

    if (this.isAtLeftOfViewport()) {
      style.push(`${className}--position-right`);
    }

    if (!PositioningHelper.isAtTopOfViewport(this.menuRef.current)) {
      style.push(`${className}--position-above`);
    }

    return style.join(' ');
  }

  computedStyles = () => {
    if (!this.menuRef.current) {
      return {};
    }
    return this.buildClassName();
  }

  handleClick = (toggleProps, event) => {
    event.stopPropagation();
    toggleProps.toggle();
  }

  generateMenuItems = (config, toggleProps) =>
    config.map(item =>
      (
        <React.Fragment key={item.label}>
          <a
            className={`${className}__item`}
            href={item.link}
            onClick={toggleProps.toggle}
          >
            <span className={`${className}__icon`}>{item.icon}</span>
            {item.label}
          </a>
          <hr className={`${className}__hr`} />
        </React.Fragment>
      ))

  render() {
    return (
      <Toggle offOnOutsideClick>
        {toggleProps => (
          <div ref={this.menuRef} className={`${className} ${this.computedStyles()}`} >
            <div
              className={`${className}__button`}
              onClick={(event) => { this.handleClick(toggleProps, event); }}
              onKeyDown={(event) => { this.handleClick(toggleProps, event); }}
              role='button'
              tabIndex={0}
            >
              <MoreIcon />
            </div>
            <DropdownWrapper on={toggleProps.on} arrowLeft={this.isAtLeftOfViewport()}>
              <div className={`${className}__content`}>
                {this.generateMenuItems(this.props.config, toggleProps)}
              </div>
            </DropdownWrapper>
          </div>
        )}
      </Toggle>
    );
  }
}

export default PopupMenu;
