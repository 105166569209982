import React from 'react';

const QstreamLogo = () => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" height="25" viewBox="0 0 21 25" width="21">
    <defs>
      <path id="a" d="M.124.695h16.283V8.39H.124z" />
      <path id="c" d="M0 8.39h20.866v16.09H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" opacity=".5">
      <g>
        <path fill="#969EA4" d="M20.743 8.39h-4.438c-.11-1.01-.298-1.492-.567-1.86l-.074-.095 1.157-4.442c.708.359 1.334.805 1.844 1.346.046.048.092.1.138.15 1.154 1.288 1.713 2.619 1.94 4.902" />
        <path fill="#969EA4" d="M16.407 1.807L13.46 5.294c-.733-.13-1.7-.2-3.009-.2h-.036c-3.181 0-4.356.404-4.99 1.081-.11.116-.209.228-.297.355-.27.368-.456.85-.566 1.86H.124c.227-2.282.785-3.613 1.939-4.9.046-.05.092-.103.139-.151 1.8-1.925 5.06-2.623 8.232-2.645 2.031.014 4.094.31 5.793 1.022l.18.091z" mask="url(#b)" />
        <path fill="#969EA4" d="M18.665 20.249c1.637-1.75 2.153-3.977 2.194-8.096h.008v-.606c0-1.232-.036-2.268-.124-3.157h-4.438c.081.724.12 1.725.12 3.157v.247c-.01 4.319-.336 4.926-.987 5.618-.634.678-1.805 1.081-4.99 1.081h-.032c-3.182 0-4.357-.403-4.99-1.081-.647-.691-.974-1.2-.984-5.604v-.261c0-1.432.038-2.433.12-3.157H.124C.035 9.28 0 10.315 0 11.547v.526h.007c.035 4.17.546 6.414 2.194 8.176 1.801 1.924 5.061 2.623 8.232 2.643.39-.003.782-.017 1.175-.041.142-.011.273.067.368.16 2.4 2.349 6.174 1.333 7.441.684.15-.076.116-.295-.049-.326-1.453-.276-1.777-1.63-1.823-1.777-.05-.165-.016-.402.133-.508.364-.246.693-.526.987-.835" mask="url(#d)" />
      </g>
    </g>
  </svg>
);
export default QstreamLogo;
