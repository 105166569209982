import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from 'config/i18n';

import './index.scss';

class CompletionStatus extends PureComponent {
  static propTypes = {
    status: PropTypes.oneOf(['NOT_STARTED', 'ACTIVE', 'COMPLETE']).isRequired,
  };

  static getStatusLabel(status) {
    return {
      ACTIVE: i18n.t('completionStatus.active'),
      COMPLETE: i18n.t('completionStatus.complete'),
      NOT_STARTED: i18n.t('completionStatus.notStarted'),
    }[status];
  }

  render() {
    const className = 'c-CompletionStatus';
    const label = CompletionStatus.getStatusLabel(this.props.status);

    return (
      <div className={`${className} ${className}--${this.props.status.toLowerCase()}`}>{label}</div>
    );
  }
}

export default CompletionStatus;
