import React, { PureComponent } from 'react';
import SummaryCard from 'components/SummaryCard';
import PropTypes from 'prop-types';
import { Grid } from '@qstream/quik';
import { Trans } from 'react-i18next';

class SummaryOfParticipation extends PureComponent {
  static propTypes = {
    proficiencyGain: PropTypes.number.isRequired,
    enrollments: PropTypes.number.isRequired,
    qstreams: PropTypes.number.isRequired,
    pastFirstPresentation: PropTypes.number.isRequired,
  }

  conditionallyRenderProficiencyGain = () => {
    const { proficiencyGain } = this.props;
    if ((proficiencyGain === Infinity) || !proficiencyGain) return null;

    const roundedProficiencyGain = Math.round(proficiencyGain);

    return (
      <Trans i18nKey='summaryParticipation.averageProficiencyGain'>
        The average proficiency gain is <span className='c-SummaryCard__text--important'>{{ proficiencyGain: roundedProficiencyGain }}</span>.
      </Trans>
    );
  }

  renderSummaryText = () => {
    const { qstreams, pastFirstPresentation, enrollments } = this.props;

    return (
      <React.Fragment>
        <Trans i18nKey='summaryParticipation.enrollments' count={enrollments}>
          You have <span className='c-SummaryCard__text--important'>{{ enrollments }}</span> enrollments
        </Trans>
        <span>{' '}</span>
        <Trans i18nKey='summaryParticipation.pastFirstPresentation' count={pastFirstPresentation}>
          <span className='c-SummaryCard__text--important'>{{ pastFirstPresentation }} participants</span> progressed past first presentation.
        </Trans>
        <span>{' '}</span>
        {this.conditionallyRenderProficiencyGain()}
        <span>{' '}</span>
        <Trans i18nKey='summaryParticipation.qstreams' count={qstreams}>
          This is calculated across <span className='c-SummaryCard__text--important'>{{ qstreams }} Qstreams</span>.
        </Trans>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Grid>
        <Grid.Column>
          <SummaryCard id='t-SummaryParticipation'>
            {this.renderSummaryText()}
          </SummaryCard>
        </Grid.Column>
      </Grid>
    );
  }
}

export default SummaryOfParticipation;
