import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@qstream/quik';
import i18n from 'config/i18n';
import OverallParticipation from './OverallParticipation';
import OverallProficiency from './OverallProficiency';
import Tagline from '../../../components/Tagline';

import './index.scss';

class OverallParticipationAndProficiency extends PureComponent {
  static className = 'c-OverallParticipationAndProficiency';

  static propTypes = {
    overallParticipation: PropTypes.shape({
      starters: PropTypes.number.isRequired,
      nonStarters: PropTypes.number.isRequired,
      percentEnrollmentsStarted: PropTypes.number.isRequired,
    }).isRequired,
    overallProficiency: PropTypes.shape({
      initialProficiency: PropTypes.number.isRequired,
      currentProficiency: PropTypes.number.isRequired,
    }).isRequired,
    cobranding: PropTypes.object,
  }

  static defaultProps = {
    cobranding: {},
  }

  render() {
    const { className } = OverallParticipationAndProficiency;
    const {
      starters,
      nonStarters,
      percentEnrollmentsStarted,
    } = this.props.overallParticipation;

    const {
      initialProficiency,
      currentProficiency,
    } = this.props.overallProficiency;

    const { cobranding } = this.props;

    return (

      <Grid>
        <div className={`${className}__title`}>
          <Grid.Column>
            <Tagline textColor={cobranding.contrastingTextColor}>
              {i18n.t('performanceTagline')}
            </Tagline>
          </Grid.Column>
        </div>
        <Grid.Column col={12} sm={6}>
          <OverallParticipation
            starters={starters}
            nonStarters={nonStarters}
            percentEnrollmentsStarted={percentEnrollmentsStarted}
          />
        </Grid.Column>
        <Grid.Column col={12} sm={6}>
          <OverallProficiency
            initialProficiency={initialProficiency}
            currentProficiency={currentProficiency}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

export default OverallParticipationAndProficiency;
