import React, { PureComponent } from 'react';
import { Text, CardWrapper } from '@qstream/quik';

import PropTypes from 'prop-types';

import './index.scss';

class Metric extends PureComponent {
  static className = 'c-Metric';
  static propTypes = {
    data: PropTypes.number.isRequired,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
  }
  static defaultProps = {
    icon: '',
  }
  renderIcon = () => {
    const { className } = Metric;
    const {
      icon,
      label,
    } = this.props;
    if (!icon) return null;
    return (
      <div className={`${className}__icon-container`}>
        <img className={`${className}__icon`} src={icon} alt={label} />
      </div>
    );
  }

  render() {
    const { className } = Metric;
    const {
      data,
      label,
    } = this.props;

    return (
      <CardWrapper>
        <div className={className}>
          {this.renderIcon()}
          <div className={`${className}__content`}>
            <span className={`${className}__data`}>
              <Text>{data}</Text>
            </span>
            <span className={`${className}__label`}>
              <Text type='secondary'>
                {label}
              </Text>
            </span>
          </div>
        </div>
      </CardWrapper>
    );
  }
}

export default Metric;
