import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@qstream/quik';
import CardTable from 'components/Tables/CardTable';
import ShowMoreContainer from 'components/ShowMoreContainer';
import i18n from 'config/i18n';

import './index.scss';

/*
*   Wraps the CardTable component in a ShowMoreContainer
*   and adds a `Load More` button
*/

class PaginatedCardTable extends PureComponent {
  static className = 'c-PaginatedCardTable';
  static propTypes = {
    // This is the only PaginatedCardTable specific prop
    pageSize: PropTypes.number,
    id: PropTypes.string,

    // These are required by CardTable
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    id: null,
    pageSize: 5,
  };

  static renderTakeMore(takeMore) {
    return (<Button onClick={takeMore} secondary>{i18n.t('tablesOfQstreams.loadMore')}</Button>);
  }

  render() {
    const {
      id,
      columns,
      data,
      pageSize,
      ...otherProps
    } = this.props;

    return (
      <div id={id} className={PaginatedCardTable.className}>
        <ShowMoreContainer
          data={data}
          pageSize={pageSize}
          render={(paginatedData, showMore, displayShowMore) => (
            <Fragment>
              <CardTable
                columns={columns}
                data={paginatedData}
                {...otherProps}
              />
              { displayShowMore ? PaginatedCardTable.renderTakeMore(showMore) : null }
            </Fragment>
          )}
        />
      </div>
    );
  }
}

export default PaginatedCardTable;
