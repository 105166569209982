import React, { PureComponent } from 'react';
import { Grid, Link, Text } from '@qstream/quik';
import leftFooterBlock from 'assets/footerBlobs/left1x.png';
import rightFooterBlock from 'assets/footerBlobs/right1x.png';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './index.scss';

class Footer extends PureComponent {
  static className = 'c-Footer';

  static propTypes = {
    t: PropTypes.func.isRequired,
    supportUrl: PropTypes.string.isRequired,
  }

  render() {
    const { className } = Footer;

    const { t } = this.props;

    return (
      <div className={className}>
        <Grid>
          <Grid.Column col={12} md={6}>
            <div className={`${className}__links`}>
              <Link href='https://qstream.com/tos/'>{t('footer.termsAndConditions')}</Link>
              {' | '}
              <Link href='https://qstream.com/privacy/'>{t('footer.privacyPolicy')}</Link>
              {' | '}
              <Link href={this.props.supportUrl}>{t('footer.technicalSupport')}</Link>
            </div>
          </Grid.Column>
          <Grid.Column col={12} md={6}>
            <div className={`${className}__copyright`}>
              <Text>
                <span
                  dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                    /**
                     * Needed so that the `&copy;` is respected.
                     */
                    __html: t('footer.copyright', { currentYear: new Date().getFullYear() }),
                  }}
                />
              </Text>
            </div>
          </Grid.Column>
        </Grid>
        <div className={`${className}__blobtainer`}>
          <img className={`${className}__blobLeft`} src={leftFooterBlock} alt='Left Footer Blob' />
          <img className={`${className}__blobRight`} src={rightFooterBlock} alt='Right Footer Blob' />
        </div>
      </div>
    );
  }
}
export default withTranslation()(Footer);
