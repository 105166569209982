import React from 'react';

const MoreIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18">
    <g fill="#969EA4" fillRule="nonzero">
      <path d="M2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM2 10.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM2 17.333a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    </g>
  </svg>

);
export default MoreIcon;
