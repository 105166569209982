import authHandler from 'config/authHandler';
import UserService from 'services/UserService';
import EnterpriseService from 'services/EnterpriseService';
import CourseService from 'services/CourseService';
import CourseGroupService from 'services/CourseGroupService';
import ProgramManagedUsersService from 'services/ProgramManagedUsersService';

import App from 'components/App';
import Dashboard from 'pages/Dashboard';
import UnauthorizedRoute from 'pages/UnauthorizedRoute';
import KitchenSink from 'pages/KitchenSink';

const states = [
  {
    name: 'app',
    url: '',
    component: App,
    abstract: true,
    resolve: [
      {
        token: 'user',
        resolveFn: () => {
          /*
          * If the JWT Token and payload have not been set, this
          * returns an empty object
          */
          const userFromJWT = authHandler.getData('user');
          if (!userFromJWT) return {};
          return UserService.getUserInitialization({ id: userFromJWT.id });
        },
      },
      {
        token: 'enterprise',
        resolveFn: () => EnterpriseService.getSettings(),
      },
    ],
    resolvePolicy: { when: 'EAGER', async: 'NOWAIT' },
  },
  {
    name: 'app.home',
    url: '/',
    component: Dashboard,
    mixpanelName: 'Insights Dashboard',
    resolve: [
      {
        token: 'activeCourses',
        resolveFn: () => CourseService.managedCourses({ completionStatus: 'ACTIVE' }),
      },
      {
        token: 'completedCourses',
        resolveFn: () => CourseService.managedCourses({ completionStatus: 'COMPLETE' }),
      },
      {
        token: 'teamMemberCount',
        resolveFn: () => ProgramManagedUsersService.enrolledManagedUsersCount({}),
      },
      {
        token: 'canDoSiteAdmin',
        resolveFn: () => UserService.getCanDo({ id: authHandler.getData('user').id, action: 'site_admin' }),
      },
      {
        token: 'courseGroups',
        resolveFn: () => CourseGroupService.managedCourseGroups(),
      },
      {
        token: 'overallParticipation',
        resolveFn: () => ProgramManagedUsersService.overallParticipation({}),
      },
      {
        token: 'overallProficiency',
        resolveFn: () => ProgramManagedUsersService.overallProficiency({}),
      },
      {
        token: 'participationSummary',
        resolveFn: () => ProgramManagedUsersService.participationSummary(),
      },
    ],
    /*
    * Don't wait for the promises to resolve before entering the state
    * https://github.com/ui-router/core/blob/master/src/resolve/interface.ts
    */
    resolvePolicy: { when: 'EAGER', async: 'NOWAIT' },
  },
  {
    name: 'app.kitchenSink',
    url: '/kitchenSink',
    component: KitchenSink,
    unprotected: true,
    devOnly: true,
  },
  {
    name: 'app.unauthorized',
    url: '/unauthorized',
    component: UnauthorizedRoute,
    unprotected: true,
  },
];

export default states;
