import { schemeAndHostFromUrl } from 'utils/UrlManipulation';

/*
* Facilitates retrieving a new token through implicit flow
*/
class TokenManagementService {
  static notLoggedInError = new Error('Not logged in');
  static renewalTimedOutError = new Error('Token renewal timed out');
  static serviceDiscoveryNotFoundError = new Error('Service discovery endpoint not found');


  constructor(params = {}) {
    // Allows us to ensure we are only requesting a new token once per expiry
    this.renewalPromise = null;

    // Time limit in ms to get a new token before we assume something has gone wrong
    this.renewalTimeoutMs = 5000;

    // A reference which will allow us to cancel the renewal timeout
    this.renewalTimeoutHandle = null;

    // Allow configuration of the URL parameter name that contains the JWT
    this.accessTokenParamName = params.accessTokenParamName || 'access_token';
  }

  /*
  * Returns a promise which is resolved when the token has been renewed
  */
  renewToken(authTokenUrl) {
    // Ensure that we only renew the token once per expiry
    if (this.renewalPromise) {
      return this.renewalPromise;
    }

    // If there is no authTokenUrl, we can't renew the token
    if (!authTokenUrl) {
      throw new Error('No authTokenUrl');
    }

    this.renewalPromise = new Promise((resolveTokenRefreshed, rejectTokenRefreshed) => {
      /*
      * Create the iFrame to retrieve the new token.
      * When it loads, and we have extracted the token from the URL,
      * we resolve this promise with the new token.
      * If we haven't managed to get a new token within the specified time,
      * reject the promise.
      */
      const iFramePromise = this.createIframe(authTokenUrl);

      return iFramePromise
        .then(token => resolveTokenRefreshed(token))
        .catch(rejectionReason => rejectTokenRefreshed(rejectionReason));
    });

    return this.renewalPromise;
  }

  /*
  * Fetches a new token
  * This differs from renewal in that it will work without a JWT present in storage by
  * determining the correct server from which to request a token.
  */
  retrieveNewToken() {
    return new Promise((resolve, reject) => {
      TokenManagementService.discoverServices()
        .then(services => this.renewToken(services.insights_frontend_url))
        .then(token => resolve(token))
        .catch(reject);
    });
  }

  /*
  * Creates an iFrame and points it at the JWT renewal URL
  */
  createIframe(authTokenUrl) {
    return new Promise((resolve, reject) => {
      this.iFrame = document.createElement('iframe');
      this.iFrame.style.visibility = 'hidden';
      this.iFrame.style.position = 'absolute';
      this.iFrame.style.display = 'none';
      this.iFrame.style.width = 0;
      this.iFrame.style.height = 0;
      this.iFrame.setAttribute('src', authTokenUrl);

      /*
      * Reject the promise if we haven't got a new token in the specified time
      */
      this.renewalTimeoutHandle = setTimeout(
        () => this.cleanupAndCall(reject, TokenManagementService.renewalTimedOutError),
        this.renewalTimeoutMs,
      );

      // Receive the token from the the iFrame via `postMessage`
      window.addEventListener(
        'message',
        (event) => {
          if (TokenManagementService.isTokenRenewalEvent(event)) {
            this.cleanupAndCall(resolve, event.data.token);
          }
        },
        false,
      );

      document.body.appendChild(this.iFrame);
    });
  }

  // Returns true if this event is a token renewal
  static isTokenRenewalEvent(event) {
    return !!(event.data && event.data.message === 'Token updated' && event.data.token);
  }

  cleanupAndCall(callback, value = null) {
    this.cleanup();
    callback(value);
  }

  cleanup() {
    // Cancel the timeout for token renewal
    clearTimeout(this.renewalTimeoutHandle);
    this.renewalTimeoutHandle = null;

    // Remove the iFrame from the DOM
    if (this.iFrame) {
      document.body.removeChild(this.iFrame);
      this.iFrame = null;
    }

    // Remove the lock on token renewal
    this.renewalPromise = null;
  }


  /*
  *  Used when we don't have a JWT in storage to determine the token renewal endpoint.
  *
  *  Returns a promise which resolves with a list of service endpoints of the following form:
  *
  *  {
  *    insights_frontend_url: 'https://xxx.xxx'
  *  }
  *
  *  Rejects with 'Not logged in' error if the request fails due to a non-success status code.
  */
  static discoverServices() {
    return new Promise((resolve, reject) => {
      const serviceDiscoveryEndpoint = TokenManagementService.serviceDiscoveryEndpoint();

      fetch(serviceDiscoveryEndpoint, { credentials: 'include' }).then(
        (resp) => {
          if (resp.ok) {
            resolve(resp.json());
          } else if (resp.status === 404) {
            reject(TokenManagementService.serviceDiscoveryNotFoundError);
          } else {
            reject(TokenManagementService.notLoggedInError);
          }
        },
      );
    });
  }

  /*
  *  Determines the location of the QS-Core server from which we retrieve our data
  */
  static coreBaseUrl() {
    /*
    *  The following cases need to be handled:
    *
    *  `site.insights.qstream.com`
    *  `site.insights.qstream.eu`
    *  `site.insights.qsdev.net`
    *  `0.0.0.0` - development
    *
    */
    const currentLocation = window.location.href;

    let baseUrl = '';

    if (currentLocation.match(/^https?:\/\/.*?\.insights\.qstream.(?:eu|com)/)) {
      /*
      *  If we're in production or a PR build, remove `.insights.` from the domain to get the
      *  QS-Core domain with client subdomain
      *  Then remove everything after the host
      */
      baseUrl = schemeAndHostFromUrl(currentLocation.replace(/\.insights\./, '.')); // Removes `.insights.` from the URL
    } else if (currentLocation.match(/^https?:\/\/.*?\.insights\.qsdev.net/)) {
      /*
      *   If we're testing in a non-production environment (dev1 or dev2) we can't reliably
      *   determine the service discovery endpoint, so for now we'll default to dev2.
      */
      baseUrl = schemeAndHostFromUrl(currentLocation.replace(/\.insights\./, '.qs-core-dev2.')); // Replaces `.insights.` with the location of dev2
    } else {
      // We're in development. Use the provided `DEV_SERVER_URL` for service discovery
      baseUrl = process.env.DEV_SERVER_URL;
    }

    return baseUrl;
  }

  /*
  *  Determines the service discovery endpoint from the current URL
  *  Returns it as a string
  */
  static serviceDiscoveryEndpoint() {
    return `${TokenManagementService.coreBaseUrl()}/service_discovery.json`;
  }
}

export default TokenManagementService;
