import React from 'react';
import PropTypes from 'prop-types';

const DataArrowIcon = ({ arrowColor }) => (
  <svg viewBox="0 0 34 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-165.000000, -748.000000)" fill={arrowColor}>
        <path d="M198.937708,770.174235 C198.811299,769.862698 198.516319,769.659407 198.188976,769.659407 L189.30948,769.659407 L189.30948,748.833081 C189.30948,748.373235 188.946433,748 188.499215,748 L175.534129,748 C175.086835,748 174.723788,748.373235 174.723788,748.833081 L174.723788,769.659407 L165.810334,769.659407 C165.482992,769.659407 165.188011,769.862698 165.061602,770.172595 C164.936788,770.484209 165.004855,770.842371 165.23663,771.080573 L181.40244,787.755004 C181.554754,787.91167 181.760625,788 181.976143,788 C182.191738,788 182.397457,787.91167 182.549847,787.756722 L198.762604,771.082291 C198.99438,770.844011 199.064118,770.485771 198.937708,770.174235" id="Fill-1" transform="translate(182.000000, 768.000000) rotate(-180.000000) translate(-182.000000, -768.000000) " />
      </g>
    </g>
  </svg>
);

DataArrowIcon.propTypes = {
  arrowColor: PropTypes.string,
};
DataArrowIcon.defaultProps = {
  arrowColor: '#00C468',
};
export default DataArrowIcon;
