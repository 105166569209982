import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { handleEnterEvent } from '@qstream/quik';
import QstreamLogo from 'components/Icons/QstreamLogo';
import key from 'weak-key';
import CardTableEmptyState from './CardTableEmptyState';

import './index.scss';

class CardTable extends PureComponent {
  static className = 'c-CardTable'

  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
      /** Leave empty if you want to hide label */
      columnTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
      /** Key to access individual cell data within data */
      dataKey: PropTypes.string.isRequired,
      /** Custom node can be passed as a child of the cell
      which recieves the cell data via a "data" prop */
      customNode: PropTypes.func,
      utilityClass: PropTypes.string,
    }).isRequired).isRequired,
    /** first arg is event, second arg is a row data object
    Adds a custom class to the table to aid in showing interactivity */
    onRowClick: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSortClick: PropTypes.func,
    sortBy: PropTypes.string,
    sortDesc: PropTypes.bool,
    emptyStateMessage: PropTypes.string.isRequired,
  }
  static defaultProps = {
    onRowClick: null,
    onSortClick: null,
    sortBy: '',
    sortDesc: true,
  }

  sortingLabel = (dataKey) => {
    const { className } = CardTable;
    const { sortBy, sortDesc } = this.props;
    const classNames = [];

    if (sortBy === dataKey) {
      classNames.push(`${className}__label--sorted`);
      if (!sortDesc) {
        classNames.push(`${className}__label--sort-asc`);
      }
    }

    return classNames.filter(Boolean).join(' ');
  }

  sortableLabel = (columnTitle) => {
    const { className } = CardTable;
    const { sortBy } = this.props;

    if (columnTitle && sortBy) return ` ${className}__label--sortable`;
    return '';
  }

  sortableLabelProps = (dataKey, columnTitle) => {
    const { onSortClick } = this.props;

    if (!columnTitle || !onSortClick) return {};

    return {
      tabIndex: 0,
      onClick: event => onSortClick(event, dataKey),
      role: 'button',
    };
  }

  tooltip = (displayTooltip, content) => (displayTooltip ? content : null);

  renderLabels = () => {
    const { className } = CardTable;
    const { columns } = this.props;

    return columns.map((columnObj, index) => {
      const { columnTitle, dataKey, utilityClass = '' } = columnObj;
      const sortClasses = `${this.sortableLabel(columnTitle)} ${this.sortingLabel(dataKey)}`;
      const sortProps = this.sortableLabelProps(dataKey, columnTitle);

      return (
        <div
          className={`${className}__label ${className}__label--${index} ${sortClasses} ${utilityClass}`}
          key={key(columnObj)}
          {...sortProps}
        >
          {columnTitle || ''}
        </div>
      );
    });
  }

  renderCells = (rowData) => {
    const { className } = CardTable;
    const { columns } = this.props;

    return columns.map((columnObj, index) => {
      const {
        dataKey,
        customNode,
        allowOverflow,
        utilityClass = '',
        displayTooltip,
      } = columnObj;

      const activeClassNames = [
        `${className}__cell`,
        `${className}__cell--${index}`,
        `${utilityClass}`,
        allowOverflow ? `${className}__cell--allow-overflow` : '',
      ].filter(Boolean).join(' ');

      return (
        <div
          className={`${activeClassNames}`}
          key={key(columnObj)}
          title={this.tooltip(displayTooltip, rowData[dataKey])}
        >
          { customNode ? customNode(rowData[dataKey]) : rowData[dataKey] }
        </div>
      );
    });
  }

  renderRows = () => {
    const { className } = CardTable;
    const { data, onRowClick } = this.props;
    const selectableClass = onRowClick ? ` ${className}__row--selectable` : '';

    return data.map((rowData, index) => {
      const selectableRowProps = onRowClick ? {
        tabIndex: 0,
        role: 'button',
        onClick: event => onRowClick(event, rowData),
        onKeyPress: event => handleEnterEvent(event, () => onRowClick(event, rowData)),
      } : '';
      return (
        <div
          {...selectableRowProps}
          className={`${className}__row ${selectableClass} ${className}__card u-Pad--m3`}
          key={index} // eslint-disable-line react/no-array-index-key
        >
          {this.renderCells(rowData)}
        </div>
      );
    });
  }

  render() {
    const { className } = CardTable;
    const {
      data,
      emptyStateMessage,
    } = this.props;

    if (!data.length) {
      const Icon = <QstreamLogo />;
      return (
        <CardTableEmptyState
          message={emptyStateMessage}
          icon={Icon}
        />
      );
    }

    return (
      <div className={`${className}`}>
        <div className={`${className}__header`}>
          {this.renderLabels()}
        </div>
        <div className={`${className}__body`}>
          {this.renderRows()}
        </div>
      </div>
    );
  }
}

export default CardTable;
