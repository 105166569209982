import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ShowMoreContainer extends PureComponent {
  static propTypes = {
    pageSize: PropTypes.number,
    render: PropTypes.func.isRequired,
    data: PropTypes.array,
  };

  static defaultProps = {
    pageSize: 5,
    data: [],
  };

  constructor(props) {
    super(props);
    this.showMoreCallback = this.showMoreCallback.bind(this);
    this.state = { maxItems: this.props.pageSize };
  }

  showMoreCallback() {
    this.setState(state => ({ maxItems: state.maxItems + this.props.pageSize }));
  }

  render() {
    const { data } = this.props;
    const { maxItems } = this.state;

    const dataToDisplay = data.slice(0, maxItems);
    const shouldDisplayShowMore = data.length > 0 && maxItems < data.length;

    return this.props.render(dataToDisplay, this.showMoreCallback, shouldDisplayShowMore);
  }
}

export default ShowMoreContainer;
