import React, { PureComponent } from 'react';
import { Grid, SearchBox, Title, Link } from '@qstream/quik';
import PropTypes from 'prop-types';
import withTableSort from 'components/Tables/withTableSort';
import PaginatedCardTable from 'components/Tables/PaginatedCardTable';
import withLoadingState from 'components/LoadingContainer/withLoadingState';
import PaginatedCardTableLoadingState from 'components/Tables/PaginatedCardTable/PaginatedCardTableLoadingState';
import i18n from 'config/i18n';

import './index.scss';

const PaginatedCardTableWithSortingAndLoading = withLoadingState(withTableSort(PaginatedCardTable));

class SearchableTablesOfQstreams extends PureComponent {
  static className = 'c-SearchableTablesOfQstreams';
  static propTypes = {
    searchableData: PropTypes.func.isRequired,
    renderSearchableSummary: PropTypes.func.isRequired,
    tables: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      promise: PropTypes.instanceOf(Promise).isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
      renderIfEmpty: PropTypes.bool.isRequired,
      onRowClick: PropTypes.func.isRequired,
      emptyStateMessage: PropTypes.string.isRequired,
      tableConfig: PropTypes.arrayOf(PropTypes.shape({
        columnTitle: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.node,
        ]),
        dataKey: PropTypes.string.isRequired,
        customNode: PropTypes.func,
        utilityClass: PropTypes.string,
      }).isRequired).isRequired,
      defaultSortBy: PropTypes.string.isRequired,
    }).isRequired).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      searchValue: '',
    };
    this.timeout = 0;
  }

  updateSearchValue = (event) => {
    const inputValue = event.target.value;
    this.setState({ inputValue }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() =>
      /*
      * The searchValue is updated using timeout,
      * so that the returned results can be delayed,
      * without delaying typing within the UI.
      */
        this.setState({ searchValue: this.state.inputValue }),
      500);
    });
  }

  filterFunction = qstream =>
    qstream.title.toUpperCase()
      .includes(this.state.searchValue.toUpperCase());

  clearSearch = () => this.setState({ inputValue: '', searchValue: '' });

  renderClearButton = () => {
    const { className } = SearchableTablesOfQstreams;
    if (this.state.searchValue) {
      return (
        <div className={`${className}__clear`}>
          <Link to='#' onClick={this.clearSearch}>{i18n.t('tablesOfQstreams.search.clear')}</Link>
        </div>
      );
    }
    return null;
  }

  renderTable = (table) => {
    const {
      id,
      title,
      promise,
      data,
      emptyStateMessage,
      renderIfEmpty,
      tableConfig,
      defaultSortBy,
      onRowClick,
    } = table;

    if (!renderIfEmpty && !data.length) return null;

    return (
      <Grid.Column key={title}>
        <Title tagName='h4'>{title}</Title>
        <PaginatedCardTableWithSortingAndLoading
          id={id}
          promise={promise}
          data={data}
          loadingState={PaginatedCardTableLoadingState}
          emptyStateMessage={emptyStateMessage}
          columns={tableConfig}
          onRowClick={onRowClick}
          defaultSortBy={defaultSortBy}
        />
      </Grid.Column>
    );
  }

  renderSearchableTables = () => {
    const {
      searchableData,
      renderSearchableSummary,
    } = this.props;

    return (
      <React.Fragment>
        <Grid.Column>
          <Title tagName='h4'>{i18n.t('tablesOfQstreams.search.title')}</Title>
          {
            renderSearchableSummary(this.filterFunction, this.state.searchValue)
          }
        </Grid.Column>
        {
          searchableData(this.filterFunction)
            .map(this.renderTable)
        }
      </React.Fragment>
    );
  }

  renderTables = () => {
    if (!this.state.searchValue) {
      return this.props.tables.map(this.renderTable);
    }
    return this.renderSearchableTables();
  }

  render() {
    const { className } = SearchableTablesOfQstreams;
    return (
      <div className={className}>
        <Grid noGutters>
          <Grid.Column col={5} sm={8} lg={10}>
            {this.renderClearButton()}
          </Grid.Column>
          <Grid.Column col={7} sm={4} lg={2}>
            <div className={`${className}__input`}>
              <SearchBox
                onChange={this.updateSearchValue}
                value={this.state.inputValue}
                onCancel={this.clearSearch}
              />
            </div>
          </Grid.Column>
        </Grid>
        <Grid>
          {this.renderTables()}
        </Grid>
      </div>
    );
  }
}
export default SearchableTablesOfQstreams;
