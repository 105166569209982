import graphQLClient from 'config/graphQLClient';
import managedCoursesQuery from 'services/gql/managedCoursesQuery.gql';

export default class CourseService {
  /*
  * Returns all courses managed by the current user.
  *
  * Can be filtered by `CourseCompletionStatus` with the following statuses:
  *
  * `ACTIVE`
  * `COMPLETE`
  * `NOT_STARTED`
  *
  * For example, to return all courses that have no enrollments:
  *
  * CourseService.managedCourses({ completionStatus: 'NOT_STARTED' })
  */
  static managedCourses(params = {}) {
    return graphQLClient
      .query({
        query: managedCoursesQuery,
        variables: params,
      }).then(result => result.data.courses);
  }
}
