import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withCoBranding } from '@qstream/quik';

import PathHelper from 'utils/PathHelper';
import calculateProficiencyGain from 'utils/calculateProficiencyGain';
import UserService from 'services/UserService';
import authHandler from 'config/authHandler';
import i18n from 'config/i18n';

// COMPONENTS
import withLoadingState from 'components/LoadingContainer/withLoadingState';

// SECTIONS
import HorizontalLinkList from 'pages/Dashboard/HorizontalLinkList';
import OverallParticipationAndProficiency from './OverallParticipationAndProficiency';
import ParticipationProficiencyLoadingState from './OverallParticipationAndProficiency/ParticipationProficiencyLoadingState';
import SummaryOfParticipation from './SummaryOfParticipation';
import SummaryOfParticipationLoadingState from './SummaryOfParticipation/SummaryOfParticipationLoadingState';
import Footer from './Footer';
import EnterpriseCoursesReportModal from './EnterpriseCoursesReportModal';
import Overview from './Overview';
import TablesOfQstreams from './TablesOfQstreams';
import AppcuesSideCard from '../Dashboard/AppcuesSideCard';

import './index.scss';

const OverallParticipationAndProficiencyWithLoading = withLoadingState(
  OverallParticipationAndProficiency,
);
const SummaryOfParticipationWithLoading = withLoadingState(SummaryOfParticipation);

class Dashboard extends PureComponent {
  static propTypes = {
    enterprise: PropTypes.instanceOf(Promise).isRequired,
    canDoSiteAdmin: PropTypes.instanceOf(Promise).isRequired,
    activeCourses: PropTypes.instanceOf(Promise).isRequired,
    completedCourses: PropTypes.instanceOf(Promise).isRequired,
    teamMemberCount: PropTypes.instanceOf(Promise).isRequired,
    courseGroups: PropTypes.instanceOf(Promise).isRequired,
    cobranding: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }).isRequired,
    overallParticipation: PropTypes.instanceOf(Promise).isRequired,
    overallProficiency: PropTypes.instanceOf(Promise).isRequired,
    participationSummary: PropTypes.instanceOf(Promise).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      canDoSiteAdmin: false,
      activeCourses: [],
      completedCourses: [],
      courseGroups: [],
      teamMemberCount: 0,
      registrationsLink: '',
      overallParticipation: {
        starters: 0,
        nonStarters: 0,
        percentEnrollmentsStarted: 0,
      },
      overallProficiency: {
        initialProficiency: 0,
        currentProficiency: 0,
      },
      participationSummary: {
        totalEnrollments: 0,
        usersPastFirstPresentation: 0,
      },
      enterpriseCoursesReportQueue: false,
      isEnterpriseCoursesReportModalOpen: false,
      supportUrl: 'https://qstream.com/support',
    };

    this.props.teamMemberCount
      .then(team => this.setState({ teamMemberCount: team.enrolledManagedUsersCount }));
    this.props.activeCourses.then(activeCourses => this.setState({ activeCourses }));
    this.props.completedCourses.then(completedCourses => this.setState({ completedCourses }));
    this.props.courseGroups.then(courseGroups => this.setState({ courseGroups }));
    this.props.canDoSiteAdmin.then(canDoSiteAdmin => this.setState({ canDoSiteAdmin }));

    this.props.overallParticipation.then((overallParticipation) => {
      this.setState({ overallParticipation });
    });

    this.props.overallProficiency.then((overallProficiency) => {
      this.setState({ overallProficiency });
    });

    this.props.participationSummary.then(participationSummary =>
      this.setState({ participationSummary }));

    this.setEnterpriseSettings();
  }

  setEnterpriseSettings = () => {
    this.props.enterprise.then(({ id: enterpriseId, supportUrl }) => {
      this.setState({
        registrationsLink: PathHelper.withApiPath(`/sites/${enterpriseId}/registrations`),
        supportUrl,
      });
    });
  }

  triggerEnterpriseCoursesReport = () => {
    UserService
      .triggerEnterpriseCoursesReport({ id: authHandler.getData('user').id })
      .then((result) => {
        this.setState({
          enterpriseCoursesReportQueue: result.enterpriseCoursesReport,
          isEnterpriseCoursesReportModalOpen: true,
        });
      });
  };

  toggleModal = () => {
    const { isEnterpriseCoursesReportModalOpen } = this.state;
    this.setState({
      isEnterpriseCoursesReportModalOpen: !isEnterpriseCoursesReportModalOpen,
    });
  }

  auxiliaryReportItems = () => {
    const {
      canDoSiteAdmin,
      registrationsLink,
    } = this.state;

    if (!canDoSiteAdmin) { return []; }

    return [
      { label: i18n.t('auxiliaryReportItems.viewRegistrations'), link: registrationsLink },
      { label: i18n.t('auxiliaryReportItems.summaryReport'), action: this.triggerEnterpriseCoursesReport },
    ];
  }

  isOverallParticipationAndProficiencyLoaded = () => Promise.all([
    this.props.overallParticipation,
    this.props.overallProficiency,
  ]);

  isParticipationSummaryPromise = () => Promise.all([
    this.props.participationSummary,
    this.props.activeCourses,
    this.props.completedCourses,
  ]);

  renderTutorial = () => (i18n.language === 'en') && <AppcuesSideCard />;

  render() {
    const className = 'p-Dashboard';

    const {
      activeCourses,
      completedCourses,
      courseGroups,
      teamMemberCount,
      enterpriseCoursesReportQueue,
      isEnterpriseCoursesReportModalOpen,
      overallParticipation,
      overallProficiency,
      participationSummary,
    } = this.state;
    const {
      cobranding,
    } = this.props;

    const proficiencyGain = calculateProficiencyGain(
      overallProficiency.initialProficiency,
      overallProficiency.currentProficiency,
    );

    return (
      <div className={className}>
        <Overview
          id='t-SummaryMetrics'
          promise={this.isOverallParticipationAndProficiencyLoaded()}
          teamMemberCount={teamMemberCount}
          activeCoursesCount={activeCourses.length}
          completedCoursesCount={completedCourses.length}
          cobranding={cobranding}
        />

        <OverallParticipationAndProficiencyWithLoading
          promise={this.isOverallParticipationAndProficiencyLoaded()}
          loadingState={ParticipationProficiencyLoadingState}
          overallParticipation={overallParticipation}
          overallProficiency={overallProficiency}
          cobranding={cobranding}
        />

        <SummaryOfParticipationWithLoading
          promise={this.isParticipationSummaryPromise()}
          loadingState={SummaryOfParticipationLoadingState}
          proficiencyGain={proficiencyGain}
          enrollments={participationSummary.totalEnrollments}
          qstreams={activeCourses.length + completedCourses.length}
          pastFirstPresentation={participationSummary.usersPastFirstPresentation}
        />

        <TablesOfQstreams
          activeCoursesPromise={this.props.activeCourses}
          activeCourses={activeCourses}
          completedCoursesPromise={this.props.completedCourses}
          completedCourses={completedCourses}
          courseGroupsPromise={this.props.courseGroups}
          courseGroups={courseGroups}
        />

        <HorizontalLinkList items={this.auxiliaryReportItems()} />

        <Footer supportUrl={this.state.supportUrl} />

        <EnterpriseCoursesReportModal
          reportSuccessfullyQueued={enterpriseCoursesReportQueue}
          isModalOpen={isEnterpriseCoursesReportModalOpen}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}

export const DashboardNoContext = Dashboard;

export default withCoBranding(Dashboard);
