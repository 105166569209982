import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Text, CardWrapper } from '@qstream/quik';

import './index.scss';

class VisualizationCard extends PureComponent {
  static className = 'c-VisualizationCard';
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    subLabel: PropTypes.string.isRequired,
    firstNode: PropTypes.node.isRequired,
    secondNode: PropTypes.node.isRequired,
  }

  static defaultProps = {
    id: '',
  }

  render() {
    const { className } = VisualizationCard;

    const {
      id,
      label,
      subLabel,
      firstNode,
      secondNode,
    } = this.props;

    return (
      <CardWrapper>
        <div id={id} className={className}>
          <div className={`${className}__title`}>
            <Text bold>{label}</Text>
            <Text type='secondary' muted>{subLabel}</Text>
          </div>
          <div className={`${className}__content`}>
            <div className={`${className}__data`}>
              {firstNode}
            </div>
            <div className={`${className}__data`}>
              {secondNode}
            </div>
          </div>
        </div>
      </CardWrapper>
    );
  }
}
export default VisualizationCard;
