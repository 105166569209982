import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

class LoadingContainer extends PureComponent {
  static propTypes = {
    /** Requires children with transparent panels so that shimmer can appear behind them. */
    children: PropTypes.node.isRequired,
  }

  render() {
    const className = 'c-LoadingContainer';
    const { children } = this.props;
    return (
      <div className={className}>
        {children}
      </div>
    );
  }
}
export default LoadingContainer;
