import React from 'react';

import CompletionStatus from 'components/Tables/CompletionStatus';
import formattedDateOrEmptyString from 'utils/formattedDateOrEmptyString';

const tableColumns = [
  {
    columnTitle: 'Title',
    dataKey: 'title',
  },
  {
    columnTitle: 'Published',
    dataKey: 'publishedAt',
    customNode: formattedDateOrEmptyString,
  },
  {
    columnTitle: 'Last Answered',
    dataKey: 'lastAnswered',
    customNode: formattedDateOrEmptyString,
  },
  {
    columnTitle: 'Participants',
    dataKey: 'enrollmentsCount',
  },
  {
    columnTitle: 'Non Starters',
    dataKey: 'nonStarters',
    customNode: () => 'n/a',
  },
  {
    dataKey: 'completionStatus',
    customNode: data => <CompletionStatus status={data} />,
  },
];

export default tableColumns;
