import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from 'config/i18n';
import VisualizationCard from 'components/VisualizationCard';
import DataBarContainer from 'components/VisualizationCard/DataBarContainer';
import DataArrow from 'components/VisualizationCard/DataArrow';
import calculateProficiencyGain from 'utils/calculateProficiencyGain';
import DataInfinity from 'components/VisualizationCard/DataInfinity';

class ProficiencyIncrease extends PureComponent {
  static propTypes = {
    initialProficiency: PropTypes.number.isRequired,
    currentProficiency: PropTypes.number.isRequired,
  };

  renderProficiencyChange = () => {
    const {
      initialProficiency,
      currentProficiency,
    } = this.props;

    if (initialProficiency === null) return this.renderDataArrow(0);

    if (initialProficiency === 0) return <DataInfinity />;

    const proficiencyGain = calculateProficiencyGain(
      initialProficiency,
      currentProficiency,
    );

    return this.renderDataArrow(proficiencyGain);
  }

  renderDataArrow = proficiencyGain => <DataArrow percentage={Math.round(proficiencyGain)} />;

  renderDataBarContainer = () => {
    const {
      initialProficiency,
      currentProficiency,
    } = this.props;

    return (
      <DataBarContainer
        barTheme='positive'
        data={[{
          number: initialProficiency,
          text: i18n.t('proficiencyCard.averageInitial'),
          withPercentage: true,
          barPercentage: initialProficiency,
        }, {
          number: currentProficiency,
          text: i18n.t('proficiencyCard.averageCurrent'),
          withPercentage: true,
          barPercentage: currentProficiency,
        }]}
      />
    );
  }

  render() {
    return (
      <VisualizationCard
        id='t-OverallProficiency'
        label={i18n.t('proficiencyCard.title')}
        subLabel={i18n.t('proficiencyCard.subLabel')}
        firstNode={this.renderProficiencyChange()}
        secondNode={this.renderDataBarContainer()}
      />
    );
  }
}
export default ProficiencyIncrease;
