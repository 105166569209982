import React from 'react';
import ReactDOM from 'react-dom';
import MixpanelProvider from 'react-mixpanel';
import { ApolloProvider } from 'react-apollo';
import { UIRouter, UIView } from '@uirouter/react';

// Polyfills `fetch` if unavailable
import 'unfetch/polyfill';

import '@qstream/quik/lib/quik.css';

import 'config/authHandler';
import router from 'config/routing/router';
import graphQLClient from 'config/graphQLClient';
import mixpanel from 'config/mixpanel';

import './config/i18n';

// Global css settings should go here
import './index.scss';

ReactDOM.render(
  (
    <ApolloProvider client={graphQLClient}>
      <MixpanelProvider mixpanel={mixpanel}>
        <UIRouter router={router}>
          <UIView />
        </UIRouter>
      </MixpanelProvider>
    </ApolloProvider>
  ),
  document.getElementById('root'),
);
