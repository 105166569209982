import React, { PureComponent } from 'react';
import { Chevron, XIcon } from '@qstream/quik';

import PropTypes from 'prop-types';

import PositioningHelper from 'utils/PositioningHelper';
import SelectOption from 'components/Input/Select/SelectOption';

import './index.scss';

class Select extends PureComponent {
  static className = 'c-Select';
  static fullscreenBreakpoint = '(max-width: 768px)';
  static componentHeight = '48px';

  static propTypes = {
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    emptyText: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    emptyText: 'Select',
    value: null,
    title: 'Select',
  };

  constructor(props) {
    super(props);
    this.optionsRef = React.createRef();
    this.selectRef = React.createRef();

    this.mediaQuery = PositioningHelper.mediaQuery(Select.fullscreenBreakpoint);

    this.state = {
      active: false,
      fullscreen: this.mediaQuery.matches,
    };

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onChange = this.onChange.bind(this);
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
  }

  componentDidMount() {
    /*
    * If the screen is resized, we want to ensure that we display the component
    * correctly. This information is used in `computedStyles`.
    */
    this.mqListener = this.mediaQuery.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    this.mediaQuery.removeListener(this.mediaQueryChanged);
  }

  onChange(newValue) {
    this.toggleDropdown();
    this.props.onChange(newValue);
  }

  toggleDropdown(evt) {
    if (evt) {
      evt.preventDefault();
    }

    this.setState({ active: !this.state.active });
  }

  /*
  * These methods are used to position the select options
  * depending on where on the screen the select control
  * is placed.
  */

  mediaQueryChanged(mq) {
    this.setState({ fullscreen: mq.matches });
  }

  verticalPositioning() {
    let top = 'auto';
    let bottom = 'auto';

    if (PositioningHelper.isAtTopOfViewport(this.selectRef.current)) {
      top = Select.componentHeight;
    } else {
      bottom = Select.componentHeight;
    }

    return { top, bottom };
  }

  horizontalPositioning() {
    let right = 'auto';
    let left = 'auto';

    if (PositioningHelper.isAtLeftOfViewport(this.selectRef.current)) {
      left = '-1px';
    } else {
      right = '-1px';
    }

    return { right, left };
  }

  computedStyles() {
    /*
    * If we haven't mounted yet or we're displaying the select full screen,
    * don't mess around with the positioning.
    */
    if (!this.selectRef.current || this.state.fullscreen) {
      return {};
    }

    return {
      ...this.verticalPositioning(),
      ...this.horizontalPositioning(),
    };
  }

  renderOptionForValue(value) {
    return (
      <SelectOption value={value} onSelect={this.onChange} key={`select-option-${value}`} selected={value === this.props.value} />
    );
  }

  renderOverlay() {
    const { className } = Select;

    if (!this.state.active) {
      return null;
    }

    /*
    * Disables the following rules:
    *
    *   jsx-a11y/click-events-have-key-events
    *   jsx-a11y/no-static-element-interactions
    *
    * because this is a visual element but we want to
    * hide the select when it is clicked.
    *
    */

    /* eslint-disable */
    return (<div className={`${className}__overlay`} onClick={this.toggleDropdown} />);
    /* eslint-enable */
  }

  renderOptions() {
    const { className } = Select;
    const { values } = this.props;

    if (this.state.active) {
      return (
        <div className={`${className}__options-container`} style={this.computedStyles()}>
          <div className={`${className}__header`}>
            <div>
              { this.props.title }
            </div>
            <div className={`${className}__close`} tabIndex="-1" role="button" onKeyPress={this.toggleDropdown} onClick={this.toggleDropdown}>
              <XIcon />
            </div>
          </div>
          <ul className={`${className}__options`} ref={this.optionsRef} role="listbox" tabIndex="-1">
            { values.map(value => this.renderOptionForValue(value)) }
          </ul>
        </div>
      );
    }

    return null;
  }

  render() {
    const { className } = Select;

    return (
      <div className={className} ref={this.selectRef} >
        <div className={`${className}__toggle`} onClick={this.toggleDropdown} role="button" tabIndex="0" onKeyPress={this.toggleDropdown}>
          <div className={`${className}__tab-outline-fix`} tabIndex="-1">
            { this.props.value || this.props.emptyText }
            <Chevron />
          </div>
        </div>
        { this.renderOptions() }
        { this.renderOverlay() }
      </div>
    );
  }
}

export default Select;

