import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, noop } from '@qstream/quik';
import i18n from 'config/i18n';
import shuttleLiftoff from 'assets/modalIcons/shuttleLiftoff.svg';
import sadCloud from 'assets/modalIcons/sadCloud.svg';

import './index.scss';

class EnterpriseCoursesReportModal extends Component {
  static className = 'c-EnterpriseCoursesReportModal';
  static propTypes = {
    reportSuccessfullyQueued: PropTypes.bool,
    isModalOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
  }
  static defaultProps = {
    reportSuccessfullyQueued: false,
    isModalOpen: false,
    toggleModal: noop,
  }

  render() {
    const { reportSuccessfullyQueued } = this.props;
    let image = '';
    let message = '';

    if (reportSuccessfullyQueued) {
      image = shuttleLiftoff;
      message = i18n.t('enterpriseCoursesReportModal.successMessage');
    } else {
      image = sadCloud;
      message = i18n.t('enterpriseCoursesReportModal.errorMessage');
    }

    return (
      <Modal
        isOpen={this.props.isModalOpen}
        toggleModal={this.props.toggleModal}
      >
        <img src={image} alt={message} />
        <p>{message}</p>
        <Button onClick={this.props.toggleModal}>{i18n.t('enterpriseCoursesReportModal.dismissButton')}</Button>
      </Modal>
    );
  }
}

export default EnterpriseCoursesReportModal;
