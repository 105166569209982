import React from 'react';

const InformationIcon = () => (
  <svg width="16px" height="16px" viewBox="-1 -1 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-149.000000, -615.000000)" fill="#2C82C9">
        <path d="M163,615 C155.3,615 149,621.3 149,629 C149,636.700001 155.3,643 163,643 C170.7,643 177,636.700001 177,629 C177,621.3 170.7,615 163,615 Z M163,619.7 C164.2,619.7 165.1,620.7 165.1,621.8 C165.1,623 164.1,623.9 163,623.9 C161.9,623.9 160.9,622.9 160.9,621.8 C160.8,620.6 161.8,619.7 163,619.7 Z M165.1,638.4 L163,638.4 L160.9,638.4 C160.3,638.4 159.8,637.9 159.8,637.299999 C159.8,636.699999 160.3,636.199999 160.9,636.199999 L161.9,636.199999 L161.9,628.1 L160.9,628.1 C160.3,628.1 159.8,627.6 159.8,627 C159.8,626.4 160.3,625.9 160.9,625.9 L163,625.9 C163.6,625.9 164.1,626.4 164.1,627 L164.1,636.199997 L165.1,636.199997 C165.7,636.199997 166.2,636.699997 166.2,637.299997 C166.2,637.899998 165.7,638.4 165.1,638.4 Z" id="Fill-1" />
      </g>
    </g>
  </svg>
);
export default InformationIcon;
