import AuthHandler from 'services/AuthHandler';
import authHandler from 'config/authHandler';
import tokenManager from 'config/tokenManager';
import TokenManagementService from 'services/TokenManagementService';
import router from 'config/routing/router';
import { subdomainFromUrl, replaceSubdomain } from 'utils/UrlManipulation';

/*
*   This hook ensures a JWT Token is available and if not, redirects to the unauthorized page
*/
class UnauthorisedHook {
  static siteDoesNotExistError = new Error('Site does not exist');

  static callback(transition) {
    const toState = transition.to();

    if (!toState.unprotected && !authHandler.isAuthenticated()) {
      /*
      *  If there is no token present in storage and we are logged into core, attempt to retrive one
      *  If we are not logged in, we kick them back to the core login page
      *  If the site doesn't exist, we kick them back to core
      */
      return tokenManager.retrieveNewToken()
        .then(token => UnauthorisedHook.ensureSiteExists(token))
        .then(() => authHandler.forceCallbacks())
        .then(() => toState)
        .catch(UnauthorisedHook.handleError);
    }

    return toState;
  }

  static handleError(err) { // eslint-disable-line complexity
    switch (err) {
      case UnauthorisedHook.siteDoesNotExistError:
        window.location.assign(replaceSubdomain(TokenManagementService.coreBaseUrl(), 'app'));
        break;
      case TokenManagementService.notLoggedInError:
        window.location.assign(`${TokenManagementService.coreBaseUrl()}/login`);
        break;
      case TokenManagementService.serviceDiscoveryNotFoundError:
        return router.stateService.target('app.unauthorized');
      default:
        throw err;
    }

    // We also want to cancel the transition to avoid making unnecessary requests
    return false;
  }

  static ensureSiteExists(token) {
    const payload = AuthHandler.payloadFromToken(token);

    if (subdomainFromUrl(window.location.href) === payload.enterprise.subdomain) {
      return Promise.resolve(token);
    }

    return Promise.reject(UnauthorisedHook.siteDoesNotExistError);
  }
}

UnauthorisedHook.action = 'onBefore';
UnauthorisedHook.criteria = {};

export default UnauthorisedHook;
