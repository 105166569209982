import { UIRouterReact, hashLocationPlugin, servicesPlugin } from '@uirouter/react';
import states from 'config/routing/states';
import hooks from 'config/routing/hooks';

/*
* Good documentation on the UI-Router API:
* https://github.com/ui-router/react/blob/master/docs/API.md
*/

const router = new UIRouterReact();

router.plugin(servicesPlugin);
router.plugin(hashLocationPlugin);

states.forEach(state => router.stateRegistry.register(state));

hooks.forEach(hook => router.transitionService[hook.action](hook.criteria, hook.callback));

router.urlRouter.otherwise('/');

export default router;
