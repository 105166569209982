import graphQLClient from 'config/graphQLClient';
import managedCourseGroups from 'services/gql/managedCourseGroups.gql';

export default class CourseGroupService {
  /*
  * Returns all course groups managed by the current user.
  */
  static managedCourseGroups(params = {}) {
    return graphQLClient
      .query({
        query: managedCourseGroups,
        variables: params,
      }).then(result => result.data.courseGroups);
  }
}
