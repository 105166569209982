import graphQLClient from 'config/graphQLClient';
import userDetailsQuery from 'services/gql/userDetailsQuery.gql';
import userInitializationQuery from 'services/gql/userInitializationQuery.gql';
import userCanDoQuery from 'services/gql/userCanDoQuery.gql';
import userEnterpriseCoursesReportQuery from 'services/gql/userEnterpriseCoursesReportQuery.gql';

export default class UserService {
  static getUserName(params) {
    return graphQLClient
      .query({
        query: userDetailsQuery,
        variables: params,
      }).then(result => result.data.user);
  }

  /*
  * Returns user Primary Nav configuration.
  *
  * primaryNav
  *
  */
  static getUserInitialization(params) {
    return graphQLClient
      .query({
        query: userInitializationQuery,
        variables: params,
      }).then(result => result.data.user);
  }

  /*
  * Returns policy permission check.
  *
  * getCanDo
  *
  */
  static getCanDo(params) {
    return graphQLClient
      .query({
        query: userCanDoQuery,
        variables: params,
      }).then(result => result.data.user.canDo);
  }

  /*
  * Triggers Enterprise Courses Report generation & dispatch.
  *
  * triggerEnterpriseCoursesReport
  *
  */
  static triggerEnterpriseCoursesReport(params) {
    return graphQLClient
      .query({
        query: userEnterpriseCoursesReportQuery,
        variables: params,
        fetchPolicy: 'no-cache',
      }).then(result => result.data.user);
  }
}
