import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Error } from 'components/ErrorBoundary/Error';
import errorLogger from 'config/errorLogger';

class ErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    errorLogger.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <Error />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
