import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import key from 'weak-key';

import './index.scss';

class HorizontalList extends PureComponent {
  static propTypes = {
    utilityClass: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.node.isRequired,
    ).isRequired,
  };

  static defaultProps = {
    utilityClass: '',
  };

  render() {
    const className = 'c-HorizontalList';
    const { items, utilityClass } = this.props;

    const activeClassNames = [
      className,
      utilityClass,
    ].filter(Boolean).join(' ');

    return (
      <ul className={`${activeClassNames}`}>
        {items.map(item => (
          <li className={`${className}__item`} key={key(item)}>{item}</li>
        ))}
      </ul>
    );
  }
}

export default HorizontalList;
