import React from 'react';
import LoadingCard from 'components/LoadingContainer/LoadingCard';
import LoadingContainer from 'components/LoadingContainer';

const PaginatedCardTableLoadingState = () => (
  <LoadingContainer>
    <LoadingCard secondaryBackground emptyCardTableRow />
    <LoadingCard secondaryBackground emptyCardTableRow />
    <LoadingCard secondaryBackground emptyCardTableRow />
    <LoadingCard secondaryBackground emptyCardTableRow />
    <LoadingCard secondaryBackground emptyCardTableRow />
  </LoadingContainer>
);
export default PaginatedCardTableLoadingState;
