/*
*   This hook ensures that states with the `devOnly` attribute
*   set to true are not available in production
*/
class DevOnlyHook {
  static productionURL = /https:\/\/insights\.qstream\.com/;

  static callback(transition) {
    const toState = transition.to();

    if (toState.devOnly && DevOnlyHook.location().match(DevOnlyHook.productionURL)) {
      return false;
    }

    return toState;
  }

  /*
  * Allows mocking for testing purposes
  */
  static location() {
    return window.location.href;
  }
}

DevOnlyHook.action = 'onBefore';
DevOnlyHook.criteria = {};

export default DevOnlyHook;
