import React from 'react';
import PropTypes from 'prop-types';

function BackgroundSplodge(props) {
  const { primaryColor } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1744" height="704" viewBox="0 0 1744 704" preserveAspectRatio="none">
      <defs>
        <linearGradient id="splodgeGradient" x1="43.107%" x2="167.116%" y1="44.452%" y2="192.919%">
          <stop offset="0%" stopColor={primaryColor} />
          <stop offset="100%" stopColor={primaryColor} stopOpacity="0" />
        </linearGradient>
      </defs>
      <path fill="url(#splodgeGradient)" fillRule="evenodd" d="M794.281 709C324.288 709 5 566.291 5 384.055S348.849 5 818.842 5C1288.828 5 1749 201.819 1749 384.055 1749 566.291 1264.267 709 794.281 709z" transform="translate(-5 -5)" />
    </svg>
  );
}

BackgroundSplodge.propTypes = {
  primaryColor: PropTypes.string,
};
BackgroundSplodge.defaultProps = {
  primaryColor: '',
};

export default BackgroundSplodge;
