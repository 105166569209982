import graphQLClient from 'config/graphQLClient';
import overallProficiencyQuery from 'services/gql/overallProficiency.gql';
import overallParticipationQuery from 'services/gql/pmUserOverallParticipationQuery.gql';
import participationSummaryQuery from 'services/gql/pmUserParticipationSummaryQuery.gql';
import enrolledManagedUsersCountQuery from 'services/gql/pmUserEnrolledManagedUsersCountQuery.gql';

export default class ProgramManagedUsersService {
  /*
  * Returns Overall Proficiency metrics.
  *
  * overallProficiency
  *
  */
  static overallProficiency(params) {
    return graphQLClient
      .query({
        query: overallProficiencyQuery,
        variables: params,
      }).then(result => result.data.programManagedUsers.overallProficiency);
  }


  /*
  * Returns Overall Participation metrics.
  *
  * overallParticipation
  *
  */
  static overallParticipation(params) {
    return graphQLClient
      .query({
        query: overallParticipationQuery,
        variables: params,
      }).then(result => result.data.programManagedUsers.overallParticipation);
  }

  /*
  * Returns Overall Patricipation Summary details
  *
  * participationSummary
  *
  */
  static participationSummary(params) {
    return graphQLClient
      .query({
        query: participationSummaryQuery,
        variables: params,
      }).then(result => result.data.programManagedUsers);
  }

  /*
  * Returns Enrolled Managed Users count.
  *
  * enrolledManagedUsersCount
  *
  */
  static enrolledManagedUsersCount(params) {
    return graphQLClient
      .query({
        query: enrolledManagedUsersCountQuery,
        variables: params,
      }).then(result => result.data.programManagedUsers);
  }
}
