import React from 'react';

import './index.scss';

const DataInfinity = () => {
  const className = 'c-DataInfinity';
  return (
    <div className={className}>
      <div className={`${className}__infin`}>
        &infin;
      </div>
    </div>
  );
};
export default DataInfinity;
