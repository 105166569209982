import React from 'react';
import { Title, Button } from '@qstream/quik';
import authHandler from 'config/authHandler';
import i18n from 'config/i18n';
import hotAirBalloon from 'assets/balloonOverMountains.png';
import './index.scss';


export const goHomeHref = () => {
  const homeUrl = authHandler.getData('iss');
  if (!homeUrl) {
    return 'https://app.qstream.com/login';
  }
  return `${homeUrl}/login`;
};

export const Error = () => {
  const className = 'c-Error';
  const homeHref = goHomeHref();

  return (
    <div className={className}>
      <div className={`${className}__container`}>
        <img src={hotAirBalloon} className={`${className}__icon`} alt={i18n.t('errorBoundary.hotAirBalloonAltText')} />

        <Title>{i18n.t('errorBoundary.message')}</Title>
        <a href={homeHref}><Button>{i18n.t('errorBoundary.returnToHomeButton')}</Button></a>
      </div>
    </div>
  );
};
