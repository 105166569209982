import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18n from 'config/i18n';

class SearchableSummaryText extends PureComponent {
  static className = 'c-SearchableSummaryText';

  static propTypes = {
    searchValue: PropTypes.string.isRequired,
    numQstreams: PropTypes.number.isRequired,
    numCourseGroups: PropTypes.number.isRequired,
  }


  renderSummaryText = () => {
    const {
      searchValue,
      numQstreams,
      numCourseGroups,
    } = this.props;

    const totalSearchResults = numQstreams + numCourseGroups;

    if (!totalSearchResults) {
      return i18n.t('tablesOfQstreams.search.summaryTextNoResults', { searchValue });
    }

    return i18n.t('tablesOfQstreams.search.summaryText', { count: totalSearchResults, searchValue });
  }

  render() {
    const { className } = SearchableSummaryText;
    const {
      numQstreams,
      numCourseGroups,
    } = this.props;

    const qstreams = i18n.t('tablesOfQstreams.search.foundQstreams', { count: numQstreams });
    const courseGroups = i18n.t('tablesOfQstreams.search.foundGroups', { count: numCourseGroups });

    return (
      <span className={className}>
        {this.renderSummaryText()}
        {` (${qstreams}, ${courseGroups})`}
      </span>
    );
  }
}
export default SearchableSummaryText;
