import mixpanel from 'config/mixpanel';

/*
*   This hook sends a mixpanel event when a state with `mixpanelName` defined is entered.
*/
class MixpanelTrackingHook {
  static callback(transition, state) {
    const toState = transition.to();

    if (toState.name === state.name) {
      mixpanel.track(`${toState.mixpanelName} Viewed`, {});
    }

    return toState;
  }
}

MixpanelTrackingHook.action = 'onEnter'; // Change this
MixpanelTrackingHook.criteria = {
  to: state => !!state.mixpanelName,
};

export default MixpanelTrackingHook;
