import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Title } from '@qstream/quik';

import './index.scss';

class DataDonut extends PureComponent {
  static className = 'c-DataDonut';

  static defaultSizing = {
    width: 148,
    strokeWidth: 12,
  }

  static propTypes = {
    percentage: PropTypes.number,
  }

  static defaultProps = {
    percentage: 0,
  }

  getCircumference = () => this.getRadius() * 2 * Math.PI;

  getRadius = () => {
    const {
      width,
      strokeWidth,
    } = DataDonut.defaultSizing;
    return (width - strokeWidth) / 2;
  }

  getStrokeDashoffset = () => {
    const { percentage } = this.props;
    const circumference = this.getCircumference();
    // The 101 here forces the circle to not be complete before 100%
    // return ((percentage / 101 / 100));
    return (circumference - ((percentage / 101) * circumference));
  }

  render() {
    const { className } = DataDonut;
    const { percentage } = this.props;

    return (
      <div className={className}>
        <span className={`${className}__label`}>
          <Title>{Math.round(percentage)}
            <div className={`${className}__symbol`}>%</div>
          </Title>
        </span>
        <div className={`${className}__container`}>
          <svg className={`${className}__svg`} viewBox="0 0 148 148">
            <circle
              className={`${className}__background`}
              r='68'
              cx='74'
              cy='74'
            />
            <circle
              className={`${className}__fill`}
              /*
              *   FIX: IE 11
              *  `transform='rotate(270, 74, 74)'` starts
              *   visualisation at 12 O'Clock rather than 3 O'Clock
              */
              transform='rotate(270, 74, 74)'
              style={{
                strokeDasharray: `${this.getCircumference()} ${this.getCircumference()}`,
                strokeDashoffset: this.getStrokeDashoffset(),
              }}
              r='68'
              cx='74'
              cy='74'
            />
          </svg>
        </div>
      </div>
    );
  }
}
export default DataDonut;
