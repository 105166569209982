import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import key from 'weak-key';

import './index.scss';

class Table extends PureComponent {
  static className = 'c-Table'

  static propTypes = {
    setup: PropTypes.shape({
      /** Leave empty if you want to hide title */
      tableTitle: PropTypes.string,
    }),

    columns: PropTypes.arrayOf(PropTypes.shape({
      /** Leave empty if you want to hide label */
      columnTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]),
      /** Key to access individual cell data within data */
      dataKey: PropTypes.string.isRequired,
      /** Custom node can be passed as a child of the cell
      which recieves the cell data via a "data" prop */
      customNode: PropTypes.func,
      utilityClass: PropTypes.string,
    }).isRequired).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,

  }
  static defaultProps = {
    setup: {
      tableTitle: null,
    },
  }

  renderLabels = () => {
    const { columns } = this.props;

    return columns.map((columnObj, index) => {
      const { columnTitle, utilityClass = '' } = columnObj;

      return (
        <th
          className={`${Table.className}__label ${Table.className}__label--${index} ${utilityClass}`}
          key={key(columnObj)}
        >
          {columnTitle || ''}
        </th>
      );
    });
  }

  renderCells = (rowData) => {
    const { columns } = this.props;

    return columns.map((columnObj, index) => {
      const { dataKey, customNode, utilityClass = '' } = columnObj;

      return (
        <td
          className={`${Table.className}__cell ${Table.className}__cell--${index} ${utilityClass}`}
          key={key(columnObj)}
        >
          { customNode ? customNode(rowData[dataKey]) : rowData[dataKey] }
        </td>
      );
    });
  }

  renderRows = () => {
    const { data } = this.props;

    return data.map((rowData, index) => (
      <tr
        className={`${Table.className}__row`}
        key={index} // eslint-disable-line react/no-array-index-key
      >
        {this.renderCells(rowData)}
      </tr>
    ));
  }

  renderTitle = () => {
    const { setup } = this.props;
    if (!setup.tableTitle) return null;

    return (<caption className={`${Table.className}__title`}>{setup.tableTitle}</caption>);
  }

  render() {
    return (
      <table className={`${Table.className}`}>
        {this.renderTitle()}
        <thead className={`${Table.className}__header`}>
          <tr>
            {this.renderLabels()}
          </tr>
        </thead>
        <tbody>
          {this.renderRows()}
        </tbody>
      </table>
    );
  }
}

export default Table;
