import authHandler from 'config/authHandler';

export default class ErrorLogger {
  constructor(airbrakeClient) {
    this.client = airbrakeClient;

    // an ENV configurable setting used to disable Airbrake when in local dev
    this.airbrakeEnabled = process.env.AIRBRAKE_ENABLED;

    // the users JWT payload
    this.payload = null;
  }

  redactTokenPayload() {
    let data;

    try {
      this.payload = authHandler.getPayload();
      if (!this.payload) {
        return null;
      }

      data = {
        iss: this.payload.iss,
        auth_token_url: this.payload.auth_token_url,
        resource_owner_id: this.payload.resource_owner_id,
        user: {
          id: this.payload.user.id,
        },
        enterprise: {
          subdomain: this.payload.enterprise.subdomain,
        },
        token: `${this.payload.token.substring(0, 15)}|${this.payload.token.slice(-15)}`,
      };
    } catch (error) {
      data = `Payload object error: ${error}`;
    }
    return data;
  }

  notifyAirbrake(error, data) {
    if (this.airbrakeEnabled === 'true') {
      this.client.notify({
        error,
        data,
      });
    }
  }

  log(error, info) {
    try {
      const jwtData = this.redactTokenPayload();
      this.notifyAirbrake(error, { info, jwtData });
    } catch (airbrakeError) {
      this.notifyAirbrake(airbrakeError);
      console.error(airbrakeError); // eslint-disable-line no-console
    }
  }
}
