import React, { PureComponent } from 'react';
import { CardWrapper, Text } from '@qstream/quik';
import PropTypes from 'prop-types';

import InformationIcon from 'components/Icons/InformationIcon';

import './index.scss';

class SummaryCard extends PureComponent {
  static className = 'c-SummaryCard';

  static propTypes = {
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    id: '',
  }

  render() {
    const { className } = SummaryCard;
    const { id } = this.props;
    return (
      <CardWrapper>
        <div id={id} className={className}>
          <div className={`${className}__icon`}>
            <InformationIcon />
          </div>
          <div className={`${className}__text`}>
            <Text type='secondary'>
              {this.props.children}
            </Text>
          </div>
        </div>
      </CardWrapper>
    );
  }
}
export default SummaryCard;
