import AirbrakeClient from 'airbrake-js';

const airbrakeClient = new AirbrakeClient({
  projectId: process.env.AIRBRAKE_ID,
  projectKey: process.env.AIRBRAKE_KEY,

  // surpress automatically raising ErrorBoundary/Window.onError events
  ignoreWindowError: process.env.AIRBRAKE_ENABLED,
});

// add custom mapping to enable Private SourceMap functionality
airbrakeClient.addFilter((notice) => {
  const webpackHash = __webpack_hash__; /* eslint-disable-line camelcase, no-undef */
  const domainRoot = window.location.origin;
  const jsMain = `${domainRoot}/main.${webpackHash}.js`;
  const sourceMap = {};

  sourceMap[jsMain] = `${domainRoot}/main.${webpackHash}.js.map`;
  notice.context.sourceMaps = sourceMap; /* eslint-disable-line no-param-reassign */
  return notice;
});

export default airbrakeClient;
